import logo from '../../assets/logo.svg';
import LeftLogo from '../../assets/LeftAerrow.svg'
import './HeaderNavBarStyle.css';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function MobileHeader(prop) {
  const location = useLocation();
  const currentPath = location.pathname;
  const [CarsData, SetcarsData] = useState(prop.language.MobileHeader[0]);
  const history = useNavigate();
  const navigate = useNavigate();

  const goBack = () => {
    if (CarsData?.navTo) {
      navigate(CarsData?.navTo);
    } else {
      history(-1);
    }
  };
  useEffect(() => {
    SetcarsData(prop.language.MobileHeader.find(a => a.RoutePath === currentPath));
  }, [currentPath]);

  return (
    <>
      {currentPath === '/' && (
        <div className="w-full h-[38px] flex justify-center items-center">
          <img src={logo} className="w-[38px] h-[38px] mr-[5%] cursor-pointer" alt="Logo" onClick={() => goBack()} />
          <p className="text-sm font-medium Def-font text-left text-white">
            101Mile
          </p>
        </div>
      )}
      {!(currentPath === '/') && CarsData?.Title && (
        <div className="h-[60px] w-full flex justify-between items-center fixed z-10">
          {CarsData?.LeftLogo && (
            <img
              src={LeftLogo}
              className="w-[38px] h-[38px] ml-[5%] md:ml-[5%] lg:ml-[5%] cursor-pointer absolute z-10"
              alt="Left Logo"
              onClick={() => goBack()}
            />
          )}
          <p className="text-sm font-medium text-left Def-font text-white flex justify-center w-full">
            {CarsData?.Title === 'Motorcycle' ? '' : CarsData?.Title}
          </p>
          {CarsData?.RightLogo && (
            <img
              src={CarsData.RightLogo}
              className="w-[38px] h-[38px] mr-[5%] cursor-pointer"
              alt="Right Logo"
            />
          )}
          {!CarsData?.RightLogo && (
            <div className='p-1' />
          )}
        </div>
      )}
    </>
  );
}

export default MobileHeader;
