import React, { useState, useEffect, useRef } from 'react';
import Card from '../Helper/cards';
import { StoreLanguage } from '../../../src/components/Store/index'
import { vehicleHistory } from '../../server/index'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Body = (prop) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const URl = location.pathname.split('/')
    const [propData, setPropData] = useState(prop.data);
    const [RawData, setRawData] = useState(prop.APIData);
    const profiles = useSelector(state => state.storeContent.UserData);

    useEffect(() => {
        setRawData(prop.APIData)
        setPropData(prop.data);
    }, [prop]);
    const hasHistoryDataFetched = useRef(false);

    useEffect(() => {
        if (profiles?.Authorization && RawData.length === 0 && URl.includes('history')) {
            GetHistoryData();
            hasHistoryDataFetched.current = true;
        }
    }, [profiles, URl, prop.data]);
    const GetHistoryData = async () => {
        try {
            const { data } = await vehicleHistory({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, profiles?.id)
            setRawData(data.results)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    return (
        <div className='w-full'>
            <div className='h-[250px] md:h-[320px] lg:h-[360px] relative'>
                <div style={{ backgroundImage: `url(${propData.MainBanner})`, borderRadius: `20px` }} className="w-[90%] h-full bg-center bg-no-repeat bg-cover mx-[5%] my-[2%]"></div>
                <div className='absolute top-0 left-[80px] right-0 bottom-0 flex items-center justify-start  mx-[5%] my-[2%]'>
                    <p className="text-left Def-font w-[300px] md:w-[270px] lg:w-[430px]">
                        <span className="text-[1rem] Def-font md:text-[1.5rem] lg:text-5xl text-left text-white">{propData.subtitle}</span>
                    </p>
                </div>
            </div>
            <div className='mx-[5%] my-[2%]'>
                <Card data={RawData} AllData={prop.data.Cards} />
            </div>
            {propData.subbanner}
        </div>
    );
};

export default Body;
