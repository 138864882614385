import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ServiceImages } from '../../server/index'
import { useDispatch, useSelector } from 'react-redux';
import DefaultImage from '../../assets/DefaultImage.svg'
import { SetMOT, SetMap, SetUserData, StoreLanguage } from '../Store/index';
// import { ErrorMessage } from '../../views/Configurations/language'


const Cards = (prop) => {
    const dispatch = useDispatch();
    const [CarsData, SetCarsData] = useState(prop.data);
    const [images, Setimages] = useState([]);
    const [Profile, setProfile] = useState();
    const profiles = useSelector(state => state.storeContent.UserData);
    const DataTrigering = (props) => {
        if (props.tags === 'MOT') {
            if (profiles?.Authorization) {
                dispatch(SetMOT(true));
            } else {
                dispatch(StoreLanguage.setProps([prop.data.ErrorLogs]))
            }
        } else if (props.tags === 'Map') {
            if (profiles?.Authorization) {
                dispatch(SetMap(true));
            } else {
                dispatch(StoreLanguage.setProps([prop.data.ErrorLogs]))
            }
        }
    };
    const GetCars = async () => {
        try {
            const { data } = await ServiceImages();
            Setimages(data)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    };
    useEffect(() => {
        GetCars()
    }, []);
    useEffect(() => {
        SetCarsData(prop.data);
    }, [prop]);

    return (
        <div className="flex justify-center items-center py-[3%]">
            <div className="w-full flex flex-col items-center">
                <div className="flex w-[90%] h-[45px] items-center mb-7">
                    <p className="text-[15px] md:text-[24px] lg:text-[34px] font-medium text-left text-white w-[40%] Def-font">
                        {CarsData.Title}
                    </p>
                    <span className="text-[10px] md:text-[20px] lg:text-xl text-right text-[#ffba40] w-[60%] Def-font">
                        {/* <NavLink to={CarsData.RightTextLink ? CarsData.RightTextLink : '/404'}> */}
                        <p className='underline'>
                            {CarsData.RightText}
                        </p>
                        {/* </NavLink> */}
                    </span>
                </div>
                <div className="w-full md:w-[90%] lg:w-[93%] flex justify-center Def-font">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {CarsData.cards.map((a, i) => (
                            <>
                                {!a.ButtonLink && <div
                                    key={i}
                                    className="w-[90%] mx-[5%] h-[285px] md:h-[360px] lg:h-[391px] bg-white rounded-2xl mb-[20px] md:mb-[20px] lg:mb-[0px] md:mr-[25px] lg:mr-[50px] cursor-pointer" onClick={() => DataTrigering(a)}
                                >
                                    <div className="w-full h-[150px] md:h-[200px] lg:h-[260px] rounded md:rounded-md lg:rounded-t-2xl">
                                        {images.results?.length > 0 && <img
                                            src={images.results[i].image_url ? images.results[i].image_url : DefaultImage}
                                            className="w-full h-full md:h-[200px] lg:h-[260px] object-cover rounded-t-2xl"
                                            alt=""
                                        />}
                                    </div>
                                    <div className="h-[131px] flex flex-row md:flex-col lg:flex-col justify-between md:justify-center lg:justify-center items-center px-5 md:px-0 lg:px-0">
                                        <p className="text-xl text-left font-medium text-[#222] w-[180px] md:w-auto lg:w-auto pb-0 md:pb-2 lg:pb-3">
                                            {a.title}
                                        </p>
                                        <div
                                            className="w-auto h-auto rounded-[10px] bg-[#2d3136] flex items-center justify-center cursor-pointer"
                                            onClick={() => DataTrigering(a)}
                                        >
                                            <p className="text-base font-medium text-center uppercase text-[#ffba40] p-2 px-[20px]">
                                                {a.button}
                                            </p>
                                        </div>
                                    </div>
                                </div>}
                                {a.ButtonLink &&
                                    <NavLink to={a.ButtonLink ? `${a.ButtonLink}` : '/404'}>
                                        <div
                                            key={i}
                                            className="w-[90%] mx-[5%] h-[285px] md:h-[360px] lg:h-[391px] bg-white rounded-2xl mb-[20px] md:mb-[20px] lg:mb-[0px] md:mr-[25px] lg:mr-[50px]"
                                        >
                                            <div className="w-full h-[150px] md:h-[200px] lg:h-[260px] rounded md:rounded-md lg:rounded-t-2xl">
                                                {images.results?.length > 0 && <img
                                                    src={images.results[i].image_url ? images.results[i].image_url : DefaultImage}
                                                    className="w-full h-full md:h-[200px] lg:h-[260px] object-cover rounded-t-2xl"
                                                    alt=""
                                                />}
                                            </div>
                                            <div className="h-[131px] flex flex-row md:flex-col lg:flex-col justify-between md:justify-center lg:justify-center items-center px-5 md:px-0 lg:px-0">
                                                <p className="text-xl text-left font-medium text-[#222] w-[180px] md:w-auto lg:w-auto  pb-0 md:pb-2 lg:pb-3">
                                                    {a.title}
                                                </p>
                                                <NavLink to={a.ButtonLink ? `${a.ButtonLink}` : '/404'}>
                                                    <div className="w-auto h-auto rounded-[10px] bg-[#2d3136] flex items-center justify-center">
                                                        <p className="text-base font-medium text-center uppercase text-[#ffba40] p-2 px-[20px]">
                                                            {a.button}
                                                        </p>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </NavLink>
                                }
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
