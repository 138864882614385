import React, { useEffect, useState, useRef } from 'react';
import Rinput from '../../Helper/RInput';
import upload from '../../../assets/UploadDoc.svg';
import TickMark from '../../../assets/GreenTickMark.svg';
import TopAerrow from '../../../assets/TopAerrow.svg'
import { SetMOT, SetScheduleMOT, StoreLanguage } from '../../Store/index';
import './RateReviewStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { SendMOT, Schedule, SideFilter } from '../../../server/index'
// import { ErrorMessage } from '../../../views/Configurations/language'
const Profile = (props) => {
    const dispatch = useDispatch();
    const profiles = useSelector(state => state.storeContent.UserData);
    const [MOTData, SetMOTData] = useState([]);
    const [RawData, SetRawData] = useState(props.data);
    const [RHoldings, setRHoldings] = useState(props.data.Holder);
    const [isDragging, setIsDragging] = useState(false);
    const [Filedata, setFiledata] = useState()
    useEffect(() => {
        setRHoldings(props.data.Holder)
    }, [props.data]);
    const handleFileInputChange = (event) => {
        setFiledata(event.target.files)
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };
    const Rinputdata = (prop) => {
        if (prop.Name === 'make') {
            filterdata(prop.UserEnterData)
        }
        const existingIndex = MOTData.findIndex((item) => item.Name === prop.Name);
        if (existingIndex !== -1) {
            const updatedMOTData = [...MOTData];
            updatedMOTData[existingIndex] = { ...updatedMOTData[existingIndex], ...prop };
            SetMOTData(updatedMOTData);
        } else {
            SetMOTData([...MOTData, { ...prop }]);
        }
    };
    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        // Handle dropped files logic here if needed
    };
    const Reduce = (converted) => {
        return converted.reduce((acc, obj) => {
            Object.keys(obj).forEach(key => {
                acc[key] = obj[key];
            });
            return acc;
        }, {});
    }
    const [Fullfillcheck, setFullfillcheck] = useState(JSON.parse(localStorage.getItem(`MOTID`)))
    useEffect(() => {
        if (Fullfillcheck?.id && Fullfillcheck?.date) {
            dispatch(SetMOT(false));
            dispatch(SetScheduleMOT(false));
        } else if (Fullfillcheck?.id && !Fullfillcheck?.date) {
            // dispatch(SetMOT(false));
            // dispatch(SetScheduleMOT(true));
        }
        if (Fullfillcheck?.id && !Fullfillcheck?.date) {
            props.changestheRaw(RawData?.Holder?.map(templateItem => {
                if (templateItem?.tags in Fullfillcheck) {
                    return {
                        ...templateItem,
                        defaultValue: Fullfillcheck[templateItem.tags],
                    };
                }
                return templateItem;
            }))
        }
        filterdata()
    }, []);
    useEffect(() => {
        if (Fullfillcheck?.id && !Fullfillcheck?.date && MOTData[0]?.Name) {
            const updatedRawData = RawData.Holder.map(templateItem => {
                const matchingMOTData = MOTData.find(item => item.Name === templateItem.tags);
                if (matchingMOTData) {
                    return {
                        ...templateItem,
                        defaultValue: matchingMOTData.UserEnterData,
                    };
                }
                return templateItem;
            });
            props.changestheRaw(updatedRawData);
        }
    }, [MOTData, Fullfillcheck, RawData.Holder]);
    const SendMOTFun = async () => {
        let MOT = Reduce(MOTData.map(a => ({ [a.Name]: a.UserEnterData })))
        let finalData = {}
        if (Filedata?.length) {
            finalData = {
                ...MOT,
                upload_vrd: Filedata?.length ? Filedata[0] : '',
            }
        } else {
            finalData = {
                ...MOT
            }
        }

        const formData = new FormData();
        for (let i in finalData) {
            formData.append(i, finalData[i])
        }
        try {
            if (Fullfillcheck?.id) {
                const { data } = await Schedule(
                    {
                        headers: {
                            Authorization: `Token ${profiles?.Authorization}`,
                        }
                    }, formData,
                    Fullfillcheck.id
                )
                localStorage.setItem(`MOTID`, JSON.stringify(data));
                props.changestheRaw(RawData?.Holder.map(templateItem => {
                    if (data && templateItem.tags in data) {
                        return {
                            ...templateItem,
                            defaultValue: data[templateItem.tags],
                        };
                    }
                    return templateItem;
                }));
            } else {
                const { data } = await SendMOT(
                    {
                        headers: {
                            Authorization: `Token ${profiles?.Authorization}`,
                        }
                    }, formData
                )
                localStorage.setItem(`MOTID`, JSON.stringify(data));
                props.changestheRaw(RawData?.Holder.map(templateItem => {
                    if (data && templateItem.tags in data) {
                        return {
                            ...templateItem,
                            defaultValue: data[templateItem.tags],
                        };
                    }
                    return templateItem;
                }))
            }
            dispatch(SetMOT(false));
            dispatch(SetScheduleMOT(true));
            dispatch(StoreLanguage.setProps([RawData.Step1Success]))
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    };
    const splitVehicleInfo = (info) => {
        const brands = Object.keys(info);
        const models = [];
        const modelVariations = [];

        for (const brand in info) {
            const brandModels = Object.keys(info[brand]);

            for (const model of brandModels) {
                const variations = info[brand][model];
                models.push(model);
                modelVariations.push(...variations);
            }
        }

        return [brands, models, modelVariations];
    }
    const filterdata = async (testData) => {
        try {
            const { data } = await SideFilter();
            const foundBrands = {};
            if (testData) {
                const testDataLower = testData.toLowerCase();

                for (const brand in data.vehicle_car_info) {
                    if (brand.toLowerCase().includes(testDataLower)) {
                        foundBrands[brand] = data.vehicle_car_info[brand];
                    }
                }
            }
            const [brands, models, modelVariations] = splitVehicleInfo(testData ? foundBrands : data.vehicle_car_info);
            const formattedData = RHoldings.map(a => {
                if (a.tags === "make") {
                    return {
                        ...a,
                        dropDown: brands
                    };
                } else if (a.tags === "model") {
                    return {
                        ...a,
                        dropDown: models
                    };
                } else if (a.tags === "engine_displacement") {
                    return {
                        ...a,
                        dropDown: data.engine_sizes
                    };
                } else {
                    return { ...a };
                }
            })
            setRHoldings(formattedData)
        } catch (error) {
        }
    }
    return (
        <>
            <div className='px-[5%] bg-[#3b3f49]'>
                {RHoldings?.length > 0 && RHoldings.map((a) => (
                    <Rinput
                        key={a.title}
                        data={{
                            Holder: a.title,
                            Icon: a.Icon,
                            tags: a.tags,
                            Type: a.typeof,
                            Clear: TopAerrow,
                            defaultValue: a?.defaultValue,
                            ...a
                        }}
                        dropDown={a.Icon ? a.dropDown : []}
                        Rinputdata={Rinputdata}
                    />
                ))}
                {((!Filedata || Filedata.length === 0) && !Fullfillcheck?.id) &&
                    <div className='my-[30px]'>
                        <div className='flex text-white p-1 pl-[10px]'>
                            {RawData.PhotoTitle} *
                        </div>
                        <div
                            className={`h-[75px] flex items-center  rounded-[10px] bg-white/[0.08] border border-[#666] ${isDragging ? 'border-blue-500' : ''
                                }`}
                            style={{ boxShadow: '0px 4px 8px 0 rgba(0,0,0,0.15)' }}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <label className='flex justify-center items-center w-full' htmlFor='file-upload'>
                                <div className='Def-font w-full relative flex '>
                                    <label className='cursor-pointer pl-[10px] '>
                                        <img src={upload} alt='' />
                                    </label>
                                    <span className='text-lg text-center text-[#41AFFF] cursor-pointer absolute pl-[15%] md:pl-[10%] lg:pl-[8%]'>{RawData.PhotoTitle}</span>
                                </div>
                            </label>
                            <input
                                id='file-upload'
                                type='file'
                                accept='image/*, video/*'
                                className='hidden'
                                onChange={handleFileInputChange}
                            />
                        </div>
                    </div>
                }
                {((Filedata || Filedata?.length > 0) || Fullfillcheck?.id) &&
                    <div className='py-[30px]'>
                        <div
                            className={`h-[75px] flex items-center  rounded-[10px] bg-white/[0.08] border border-[#666]`}
                        >
                            <label htmlFor='file-upload' className='cursor-pointer p-[17px]'>
                                <img src={TickMark} alt='' />
                            </label>
                            <p className='text-lg text-center Def-font'>
                                <span className='text-lg text-center text-[#3cc676]'>{RawData.PhotoTitleSucess}</span>
                            </p>
                        </div>
                    </div>
                }
                <div
                    className='w-full mt-[3%] md:my-[5%] lg:mt-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer'
                    onClick={() => SendMOTFun()}
                >
                    <p className='text-xl font-bold text-center Def-font uppercase text-[#333]'>{RawData.Button}</p>
                </div>
            </div>
        </>
    );
};

export default Profile;
