import React, { useEffect, useState } from 'react';
import countries from 'countries-list';
import './LoginStyle.css';

const LoginWithNumber = (props) => {
    const [selectedCountry, setSelectedCountry] = useState(
        props?.userInputData?.country ? props.userInputData.country : 'Hong Kong'
    );
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [phoneNumber, setphoneNumber] = useState('');

    const countryList = Object.entries(countries.countries).map(([code, country]) => ({
        name: country.name,
        flag: `https://flagpedia.net/data/flags/w580/${code.toLowerCase()}.png`,
        phoneCode: country.phone,
    }));

    const filteringCountry = () => {
        return countryList.filter((country) =>
            country.name.toLowerCase().includes(selectedCountry.toLowerCase())
        );
    };

    const handleInputChange = (event) => {
        const filteredCountries = filteringCountry();
        if (filteredCountries.length > 0) {
            const selectedCountry = filteredCountries[0];
            setphoneNumber(event.target.value)
            props.countryPhoneNumber({
                [props?.UserData?.isEmailOrNumber ? 'Number' : 'email']: event.target.value,
                CountryName: selectedCountry.name,
                phoneCode: selectedCountry.phoneCode,
            });
        }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const changeCountry = (country) => {
        setSelectedCountry(country.name);
        setShowDropdown(false);
        props.countryPhoneNumber({
            CountryName: country.name,
            phoneCode: country.phoneCode,
        });
        setphoneNumber('')
    };

    const setSearchInput = (inputData) => {
        const filterCriteria = inputData.toLowerCase();
        const filteredData = countryList.filter((item) =>
            item.name.toLowerCase().includes(filterCriteria)
        );
        setFilteredData(filteredData);
    };

    return (
        <div className={`w-full rounded-[10px] bg-white/[0.15] Def-font relative ${props?.UserData?.isEmailOrNumber ? 'h-[124px] md:h-[144px] lg:h-[160px]' : 'h-[65px]'}`}>
            {props?.UserData?.isEmailOrNumber && <>
                {props.data.ConformScreen && <div className="w-full h-full absolute z-9"></div>}
                <div className="h-1/2 flex items-center rounded-t-[10px] border border-[#bbb] cursor-pointer"
                    onClick={toggleDropdown}
                >
                    <img
                        src={filteringCountry()[0].flag}
                        className="w-8 h-[21px] rounded m-[20px] object-none object_fit"
                        alt="country-flag"
                    />
                    <div className="align-center contents">
                        <p className="text-lg text-left text-white w-full">
                            {filteringCountry()[0].name}
                        </p>
                        <div className="flex justify-end w-full">
                            <svg
                                className="w-4 m-[20px] h-4 ml-2 text-white"
                                aria-hidden="true"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                {showDropdown && (
                    <>
                        <div className="w-full min-h-[20px] max-h-[241px] z-8 absolute rounded-b-[10px] bg-[#585c64] overflow-y-scroll border border-[#bbb] z-10">
                            <div className="w-full flex justify-center">
                                <div className="relative w-[90%] py-[7px]">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        id="simple-search"
                                        className="bg-[#4D5763] border-gray-300 Def-font text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white"
                                        placeholder={'Search Country'}
                                        required
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                </div>
                            </div>
                            {filteredData.length > 0
                                ? filteredData.map((country) => (
                                    <option
                                        key={country.name}
                                        value={country.name}
                                        className="text-white border Def-font border-[#bbbbbb70] h-[40px] center_aline cursor-pointer"
                                        onClick={() => changeCountry(country)}
                                    >
                                        {country.name}
                                    </option>
                                ))
                                : countryList.map((country) => (
                                    <option
                                        key={country.name}
                                        value={country.name}
                                        className="text-white border Def-font border-[#bbbbbb70] h-[40px] center_aline cursor-pointer"
                                        onClick={() => changeCountry(country)}
                                    >
                                        {country.name}
                                    </option>
                                ))}
                        </div>
                    </>
                )}
                <div className="h-1/2 flex items-center rounded-b-[10px] border border-[#bbb]">
                    <div className="text-lg w-auto text-left text-[#aaa] ml-[20px] mr-[10px]">
                        {`+${filteringCountry()[0].phoneCode}`}
                    </div>
                    <div>
                        <span className="inline-block mt-[7px] mr-[10px] h-[15px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"></span>
                    </div>
                    <input
                        type="number"
                        className="text-lg w-full h-full bg-transparent text-left text-[#aaa] border-none outline-none"
                        placeholder={props.data.PhoneNumberHolder}
                        onChange={handleInputChange}
                        value={phoneNumber}
                        onWheel={(event) => event.currentTarget.blur()}
                        defaultValue={props?.userInputData?.phone_number} />
                </div>
            </>}
            {!props?.UserData?.isEmailOrNumber && <>
                <div className="h-[65px] flex items-center rounded-[10px] border border-[#bbb]">
                    <input
                        disabled={props?.userInputData?.email}
                        type="text"
                        className="text-lg w-full h-full bg-transparent text-left text-[#aaa] border-none outline-none px-[10px]"
                        placeholder={props?.data?.content?.EmailHolder || 'Enter Email'}
                        onChange={handleInputChange}
                        onWheel={(event) => event.currentTarget.blur()}
                        defaultValue={props?.userInputData?.email} />
                </div>
            </>}
        </div>
    );
};

export default LoginWithNumber;
