import language from './views/Configurations/language';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import logo from './assets/logo.svg';
import ImgSlider from './components/ImgSlider/ImgSlider';
import Cards from './components/HomePageCard/Cards';
import Body from './components/Body/Index'
import BookMark from './components/services/BookMark';
import Services from './components/services/index';
import Cardscomparing from './components/services/CardsComparing'
import ContactUs from './components/services/contact_us'
import Comparing from './components/services/comparing';
import Service from './components/services/index';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RouteLayout from './components/Root'
import BGImage from './assets/HomePage_bg_image.svg'
import { GetBanners, vehicleHistory, GetSearchCar } from './server/index'
import { StoreLanguage } from '../src/components/Store/index';
import MotHistory from '../src/components/Helper/MotHystory'
import HistoryList from '../src/components/historyList'
function App() {
  const dispatch = useDispatch()
  const [carsData1, setCarsData1] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [search, setsearch] = useState([]);
  // const profiles = useSelector(state => state.storeContent.UserData);
  let SelectedLan = useSelector(state => state.storeContent.language);
  const searchResult = useSelector(state => state.storeContent.search);
  const [isOnline, setIsOnline] = useState(true);

  const checkOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };
  useEffect(() => {
    GettingBanners()
    checkOnlineStatus();
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);
  useEffect(() => {
    if (searchResult) {
      searchFun(searchResult)
    }
  }, [searchResult]);

  const searchFun = async () => {
    try {
      const { data } = await GetSearchCar(searchResult)
      if (data?.results) {
        setsearch(data.results)
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(StoreLanguage.setProps([{
          Title: '500 server error',
          SubTitle: '500 server error',
          Styling: {
            Icon: 'Error',
          }
        }]));
      } else {
        let temp = []
        for (let er in error?.response?.data) {
          temp.push({
            Title: er,
            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
            Styling: {
              Icon: 'Error',
            }
          })
        }
        dispatch(StoreLanguage.setProps(temp))
      }
    }
  }
  const handleOnlineStatusChange = () => {
    checkOnlineStatus();
  };
  if (!SelectedLan) {
    SelectedLan = localStorage.getItem(`Language`) ? localStorage.getItem(`Language`) : 'eng'
  }
  const [propData, setPropData] = useState(language[SelectedLan]);
  useEffect(() => {
    setPropData(language[SelectedLan]);
  }, [SelectedLan]);
  const GettingBanners = async () => {
    try {
      const { data } = await GetBanners()
      setCarsData1(data.results)
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(StoreLanguage.setProps([{
          Title: '500 server error',
          SubTitle: '500 server error',
          Styling: {
            Icon: 'Error',
          }
        }]));
      } else {
        let temp = []
        for (let er in error?.response?.data) {
          temp.push({
            Title: er,
            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
            Styling: {
              Icon: 'Error',
            }
          })
        }
        dispatch(StoreLanguage.setProps(temp))
      }
    }
  }
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RouteLayout />,
      children: [
        {
          path: '/',
          element: <>
            <ImgSlider data={carsData1} AllData={propData.SliderImageData} />
            <Cards data={propData.CardsData} AllData={propData.CardData.Cards} />
          </>
        },
        {
          path: '/service',
          element: <>
            <Services data={{ ...propData.Services, CardData: { ...propData.CardData } }} />
          </>
        },
        {
          path: '/product-detail',
          element: <>
            <Service data={propData} />
          </>
        },
        {
          path: '/mot_history',
          element: <>
            <MotHistory />
          </>
        },
        {
          path: '/product-detail/:Productid',
          element: <>
            <Comparing data={propData} />
          </>
        },
        {
          path: '/product-detail/sell',
          element: <>
            <Service data={propData} />
          </>
        },
        {
          path: '/product-comparison/:Productid',
          element: <>
            <Cardscomparing data={propData} />
          </>
        },
        {
          path: '/product-comparison',
          element: <>
            <Cardscomparing data={propData} />
          </>
        },
        {
          path: '/History',
          element: <>
            <Body data={propData.CardData} APIData={historyData} />
          </>
        },
        {
          path: '/contact_us',
          element: <>
            <ContactUs />
          </>
        },
        {
          path: '/search',
          element: <>
            <Body data={propData.SearchCar} APIData={search} />
          </>
        },
        {
          path: '/Bookmark',
          element: <>
            <BookMark data={propData} />
          </>
        },
        {
          path: '/history_list',
          element: <>
            <HistoryList data={propData} />
          </>
        },
        {
          path: '*',
          element: <>
            <header className="App-header" >
              <img src={logo} className="App-logo" alt="logo" />
              <div className='shade-color pt-5'>{propData.welcomeMsg}</div>
              <div className='pt-5'>{propData.ServerError}</div>
            </header >
          </>
        }
      ]
    },
  ])
  return (
    <>
      {!isOnline ? <header className="App-header" >
        <img src={logo} className="App-logo" alt="logo" />
        <div className='shade-color pt-5'>{propData.welcomeMsg}</div>
        <div className='pt-5'>{propData.InternetIssuesMsg}</div>
      </header >
        :
        <div className="App bg-cover bg-center bg-no-repeat h-full"
          style={{
            backgroundImage: `url(${BGImage})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat',
            height: '100%'
          }}
        >
          <RouterProvider router={router} />
        </div >}
    </>
  );
}

export default App;
