import React, { useState, useEffect } from 'react';
import MOTDetails from './MOTDetails'
import Schedule from './Schedule'
import Post from './Post'
import RateReview from './RateReview'
import './RateReviewStyle.css'
import { useSelector } from 'react-redux'
import { SetMOT, SetRatting, SetScheduleMOT, SetAddNewPost, PatchPostedcar } from '../../Store/index'
import { useDispatch } from 'react-redux';
import LeftLogo from '../../../assets/LeftAerrow.svg'
const Profile = (props) => {
    const PatchData = useSelector(state => state.storeContent.PatchData);
    const dispatch = useDispatch()
    const AddData = useSelector(state => state.storeContent.AddNewPost);
    const MOTServices = useSelector(state => state.storeContent.MOTServices);
    const ScheduleMOT = useSelector(state => state.storeContent.ScheduleMOT);
    const Ratting = useSelector(state => state.storeContent.Ratting);
    const [RawdataforMOT, setRawdataforMOT] = useState(props.data.MOTDetails);
    const preview = (event) => {
        dispatch(SetScheduleMOT(false))
        dispatch(SetMOT(true))
    }
    const Close = (event) => {
        dispatch(PatchPostedcar([]))
        setRawdataforMOT({ ...props.data.MOTDetails })
        localStorage.removeItem('MOTID')
        dispatch(SetMOT(false))
        dispatch(SetScheduleMOT(false))
        dispatch(SetAddNewPost(false))
        dispatch(SetRatting({
            ID: 0,
            Status: false
        }))
    }

    const changedata = (prop, status) => {
        if (status) {
            setRawdataforMOT({ ...props.data.MOTDetails })
        } else {
            setRawdataforMOT({ ...RawdataforMOT, Holder: prop })
        }
    }

    useEffect(() => {
        setRawdataforMOT(props.data.MOTDetails)
    }, [props.data]);

    return (
        (AddData || MOTServices || ScheduleMOT || Ratting.Status) && (
            <div className="w-full h-screen bg_blur fixed top-0 z-[12] overflow-y-scroll Def-font">
                <div className='absolute w-[30px] h-[30px] rounded-full items-center justify-center right-[5%] z-10 md:block lg:block flex text-xl cursor-pointer bg-[#62656D] text-white top-5' onClick={() => Close()}>x</div>
                <div className='w-full md:w-[35%] lg:w-[45%] h-full absolute right-0 bg-[#3B3F49]'>
                    <div className='w-full h-full'>
                        <div className='py-[50px] flex justify-center items-center'>
                            <div className='absolute left-5'>
                                {ScheduleMOT && <img
                                    src={LeftLogo}
                                    className="w-[38px] h-[38px] cursor-pointer"
                                    alt="Left Logo"
                                    onClick={() => preview()}
                                />}
                            </div>
                            <div className=''>
                                {ScheduleMOT && <p className="text-2xl text-center text-white">Schedule</p>}
                                {AddData && <p className="text-2xl text-center text-white">{!PatchData?.id ? props?.data?.postData?.title : props?.data?.postData?.editPost }</p>}
                                {Ratting.Status && <p className="text-2xl text-center text-white">Rate & Review</p>}
                                {MOTServices && <p className="text-2xl text-center text-white">{props.data.MOTDetails.title}</p>}
                            </div>
                        </div>
                        <hr className="h-px bg-[#72787C] border-0" />
                        {Ratting.Status && <RateReview data={props.data.Ratinges} />}
                        {AddData && <Post data={props.data.postData} />}
                        {MOTServices && <MOTDetails data={RawdataforMOT} changestheRaw={changedata} />}
                        {ScheduleMOT && <Schedule data={props.data.Schedule} RawdataforMOT={RawdataforMOT} changestheRaw={changedata} />}
                    </div>
                </div>
            </div>
        )
    );
};

export default Profile;
