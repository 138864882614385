import React, { useState, useEffect, useCallback } from 'react';
import './LoginStyle.css'
import { useDispatch, useSelector } from 'react-redux';
// import { ErrorMessage } from '../../../views/Configurations/language';
import { SetUserData, StoreLanguage } from '../../Store/index';
import {
    LoginSocialGoogle,
    LoginSocialFacebook
} from 'reactjs-social-login';
const LoginAlternative = (prop) => {
    const dispatch = useDispatch()
    const REDIRECT_URI = '/';
    const [CarsData, SetcarsData] = useState(prop)
    const [logindata, Setlogindata] = useState(prop.data.dataforlogn.LoginWithSuport)

    useEffect(() => {
        Setlogindata(prop.data.dataforlogn.LoginWithSuport)
        SetcarsData(prop)
    }, [prop]);
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState();
    useEffect(() => {
        dispatch(SetUserData({ provider: provider, ...profile }))
    }, [profile]);
    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
    }, []);
    const continuewithdevice = (Data) => {
        if (Data.Info === 'Numbered') {
            let { LoginWithNumber, ...rest } = prop.LoginData;
            LoginWithNumber = true
            prop.DataToToggle({ LoginWithNumber, ...Object.fromEntries(Object.keys(rest).map(key => [key, false])) })
        } else if (Data.Info === 'Google') { }
    }
    const loginwithGmail = () => {
        window.location.replace("https://101mile.com/backend_service/api/v1/user/google_auth_url/");
    }
    return (
        <div className="w-[515px] h-[587px] p-[30px] md:p-[40px] lg:p-[50px] Def-font">
            <div className='mb-[20px] md:mb-[30px] lg:mb-[40px]'>
                <p className="text-[20px] md-text-[27px] lg:text-[30px] font-medium text-left text-white">
                    {logindata.Title}
                </p>
                <p className="text-[15px] md-text-[17px] lg:text-[20px] text-left mb-[20px] text-white">
                    {logindata.SubTitle}
                </p>
            </div>
            {
                logindata.content.map((a, index) =>
                    a.Info === "Numbered" ? (
                        <div
                            key={index}
                            className="w-full h-[85px] pb-[30px] cursor-pointer"
                            onClick={() => continuewithdevice(a)}
                        >
                            <div className="w-full h-full Div_Btn rounded-[20px] bg-white/[0.15] border border-[#bbb] pl-[20px]">
                                <img src={a.Icon} className="w-[25px] h-[37px] mr-2" alt="Location" />
                                <p className="text-lg text-left text-white">{a.title}</p>
                            </div>
                        </div>
                    ) : a.Info === "Google" ? (
                        <div className='' onClick={() => loginwithGmail()}>
                            <div
                                key={index}
                                className="w-full h-[85px] pb-[30px] cursor-pointer"
                            >
                                <div className="w-full h-full Div_Btn rounded-[20px] bg-white/[0.15] border border-[#bbb] pl-[20px]">
                                    <img src={a.Icon} className="w-[25px] h-[37px] mr-2" alt="Location" />
                                    <p className="text-lg text-left text-white">{a.title}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                        </>
                        // <LoginSocialFacebook
                        //     appId={process.env.REACT_APP_FB_APP_ID || '1010561779882779'}
                        //     fieldsProfile={
                        //         'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        //     }
                        //     onLogoutSuccess={onLogoutSuccess}
                        //     redirect_uri={REDIRECT_URI}
                        //     onResolve={({ provider, data }) => {
                        //         prop.datafromalternavive({ provider, ...data })
                        //         setProvider(provider);
                        //         setProfile(data);
                        //     }}
                        //     onReject={error => {
                        //         if (error?.response?.status === 500) {
                        //             dispatch(StoreLanguage.setProps([{
                        //                 Title: '500 server error',
                        //                 SubTitle: '500 server error',
                        //                 Styling: {
                        //                     Icon: 'Error',
                        //                 }
                        //             }]));
                        //         } else {
                        //             let temp = []
                        //             for (let er in error?.response?.data) {
                        //                 temp.push({
                        //                     Title: er,
                        //                     SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        //                     Styling: {
                        //                         Icon: 'Error',
                        //                     }
                        //                 })
                        //             }
                        //             dispatch(StoreLanguage.setProps(temp))
                        //         }
                        //     }}
                        // >
                        //     <div
                        //         key={index}
                        //         className="w-full h-[85px] pb-[30px] cursor-pointer"
                        //     >
                        //         <div className="w-full h-full Div_Btn rounded-[20px] bg-white/[0.15] border border-[#bbb] pl-[20px]">
                        //             <img src={a.Icon} className="w-[25px] h-[37px] mr-2" alt="Location" />
                        //             <p className="text-lg text-left text-white">{a.title}</p>
                        //         </div>
                        //     </div>
                        // </LoginSocialFacebook>
                    )
                )
            }
            <div className='' onClick={() => { CarsData.UnableSignin() }}>
                <p className="text-sm text-center text-white cursor-pointer"> {logindata.Skip}</p>
            </div>
        </div>
    );
};

export default LoginAlternative;