import React, { useEffect, useState } from 'react';
import Stars from '../../../assets/Stars/Star';
import Rinput from '../../Helper/RInput'
import { RequesrRating } from '../../../server/index'
import './RateReviewStyle.css'
import { useDispatch, useSelector } from 'react-redux';
// import { ErrorMessage } from '../../../views/Configurations/language'
import { StoreLanguage, SetRatting } from '../../Store/index'
const Profile = (props) => {
    const dispatch = useDispatch()
    const [RawData, SetRawData] = useState(props.data);
    const [SubRating, setSubRating] = useState(props.data.SubData)
    const profiles = useSelector(state => state.storeContent.UserData);
    const Ratting = useSelector(state => state.storeContent.Ratting);
    const [Inputtitle, setInputtitle] = useState({})
    const [Suntitle, setSuntitle] = useState('')
    const [AvgRatting, setAvgRatting] = useState()
    const Rinputdata = (prop) => {
        setInputtitle(prop)
    }
    const PostApi = async () => {
        let result = {};
        let FinalData = SubRating.map(a => ({ [a.key]: a.Stars ? a.Stars - 1 : 0 }));
        FinalData.forEach(obj => {
            Object.assign(result, obj);
        });
        try {
            const { data } = await RequesrRating({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                ...result,
                "car_vehicle": Ratting.ID,
                "review_title": Inputtitle.UserEnterData,
                "experience_details": Suntitle
            }
            )
            dispatch(SetRatting({
                ID: '',
                Status: false
            }))
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    useEffect(() => {
        const { total, numFields } = SubRating.reduce((acc, curr) => {
            acc.total += curr.Stars;
            acc.numFields++;
            return acc;
        }, { total: 0, numFields: 0 });
        const average = total / numFields;
        setAvgRatting(average.toFixed(2) - 1);
    }, [SubRating]);
    const Clickcheck = (prop, title) => {
        setSubRating(SubRating.map(a => a.key === title ? {
            ...a,
            Stars: prop + 1
        } : a))
    }
    const Inputdata = (event) => {
        setSuntitle(event.target.value)
    };
    return (
        <div className='flex items-center justify-center bg-[#3B3F49] Def-font'>
            <div className='flex flex-col w-[95%] md:w-[85%] lg:w-[90%]'>
                <div className='pt-[50px]'>
                    <div className='flex py-[30px]'>
                        <p className="text-xl font-medium text-center text-white w-1/2 flex">Rate your experience</p>
                        <p className="text-sm text-right text-[#aaa] w-1/2">{AvgRatting > 0 ? AvgRatting.toFixed(2) : 0} Overall rating</p>
                    </div>
                    <div className="w-full h-0.5 opacity-50 bg-[#ffffff26]"></div>
                </div>
                {SubRating.map((a, i) => (
                    <React.Fragment key={a.title}>
                        {!a.ShowDropDownData && (
                            <div>
                                <div className='h-[64px] md:h-[84px] lg:h-[98px] flex items-center justify-start'>
                                    <p className="text-xl text-left text-[#aaa] pl-[20px] pr-[10px] w-[40%]">
                                        {a.title}
                                    </p>
                                    <div className='w-[60%] flex'>
                                        <div className='w-[250px] h-[40px] flex justify-between'>
                                            {Array.from({ length: parseInt(RawData.TotalStars) }).map((b, i) => (
                                                <React.Fragment key={b}>
                                                    <Stars data={{ StarNumber: i + 1, color: a.Stars <= i + 1 ? RawData.DarkColorStarts : RawData.ColorStar }} title={a.key} Clickcheck={Clickcheck} />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {i + 1 !== RawData.length && (
                                    <div className="w-full h-0.5 opacity-50 bg-[#ffffff26]"></div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ))}
                <div className='py-[20px]'>
                    <Rinput data={{ Holder: RawData.ReviewHolder, Type: 'text', tags: 'review_title' }} Rinputdata={Rinputdata} />
                </div>
                <textarea
                    rows="4"
                    className="outline-none w-full h-full bg-white/[0.15] border border-[#bbb] p-[20px] pr-[40px] text-white text-lg rounded-[10px]"
                    placeholder={RawData.MessageHolder}
                    onBlur={Inputdata}
                />
                <div className="w-full mt-[3%] md:my-[5%] lg:mt-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer" onClick={() => PostApi()}>
                    <p className="text-xl font-bold text-center uppercase text-[#333]">
                        {RawData.Button}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Profile;
