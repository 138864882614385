import BgBanner from '../../assets/BgBanner.svg';
import React, { useState, useEffect } from 'react';
import { setProps } from '../Store/index';
import { useDispatch, useSelector } from 'react-redux';
import { GetbackRequest, get_in_touch } from '../../server/index';
// import { ErrorMessage } from '../../views/Configurations/language'
import { StoreLanguage } from '../Store/index'
const HomePageBanner = (props) => {
    const dispatch = useDispatch();
    const [propData, setPropData] = useState(props.data);
    const profiles = useSelector(state => state.storeContent.UserData);
    useEffect(() => {
        setPropData(props.data);
    }, [props]);

    const PopUpdata = async () => {
        try {
            await GetbackRequest({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, profiles?.id)
            dispatch(setProps([propData.GetinTouch]));
        } catch (error) {
            // let temp = []
            // for (let er in error?.response?.data) {
            //     temp.push({
            //         Title: er,
            //         SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
            //         Styling: {
            //             Icon: 'Error',
            //         }
            //     })
            // }
            // dispatch(StoreLanguage.setProps(temp))
            dispatch(setProps([propData.GetinTouch]));
        }
    };
    const [popUpData, setpopUpData] = useState(false);
    const [formData, setFormData] = useState({ email: '', description: '' });
    const sendRequest = async () => {
        try {
            await get_in_touch(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                ...formData
            })
            setpopUpData(false)
            setFormData({ email: '', description: '' });
            dispatch(StoreLanguage.setProps([{
                Title: 'Request Submitted Successfully',
                SubTitle: 'Thank you! Our team will contact you shortly.',
                Styling: {
                    Icon: 'Success',
                }
            }]));
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    const ClickOnCall = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setpopUpData(true);
    }
    const close = () => {
        setpopUpData(false)
        setFormData({ email: '', description: '' })
    }
    const send = () => {
        sendRequest()
    }
    const handleChange = (event) => {
        let { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <div className='w-full'>
            {popUpData && (
                <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                    <div className='w-full h-[100vh] flex justify-center items-center'>
                        <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[25%] rounded-lg'>
                            <div className='p-4'>
                                <div className='w-full flex justify-end'>
                                    <div className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => close()}>
                                        <div className="flex items-center justify-center">x</div>
                                    </div>
                                </div>
                                <div className='px-4 pb-[20px]'>
                                    <div>
                                        <div className='flex items-start flex-col text-white py-5'>
                                            <div className='text-lg pb-1 flex flex-col gap-3 w-full'>
                                                <div className='flex items-start flex-col'>
                                                    <div>Email</div>
                                                    <input
                                                        type="email"
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[50px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Email"
                                                        value={formData.email}
                                                        name='email'
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='flex items-start flex-col'>
                                                    <div>Description</div>
                                                    <textarea
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[150px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Description"
                                                        rows="4"
                                                        value={formData.description}
                                                        name='description'
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-full flex items-center pb-2 justify-around`}>
                                    <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => close()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Close
                                        </p>
                                    </div>
                                    <div className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center" onClick={() => send()} style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Send
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                style={{ backgroundImage: `url(${BgBanner})` }}
                className='flex flex-col justify-center items-center h-[200px] md:h-[300px] lg:h-[500px] w-full bg-repeat-none bg-cover'
            >
                <p className="text-[20px] Def-font md:text-[28px] lg:text-[34px] font-medium text-center text-white">{propData?.title}</p>
                <p className="w-[200px] md:w-[318px] lg:w-[418px] Def-font text-md md:text-lg lg:text-xl text-center text-white">{propData?.subtitle}</p>
                <div className="h-12 mt-[10px] lg:mt-[30px] cursor-pointer" onClick={() => ClickOnCall()}>
                    <p className="text-sm lg:text-base font-medium Def-font text-left uppercase text-[#333] Choose_Best h-[27px] md:h-[34] lg:h-[48px] w-[160px] md:w-[200px] lg:w-[227px] rounded-[5px] lg:rounded-[10px] flex justify-center items-center">{propData.button}</p>
                </div>
            </div>
        </div>
    );
};

export default HomePageBanner;
