import React, { useEffect, useState } from 'react';
import Success from '../../assets/Sucess.svg';
import Error from '../../assets/Error.svg';
import Alert from '../../assets/Alert.svg';
import { SetProfileStatus, SetUserData } from '../Store/index'
import { useDispatch, useSelector } from 'react-redux';
import './PopUpStyle.css';

const PopUp = (props) => {
    const dispatch = useDispatch()
    const profiles = useSelector(state => state.storeContent.UserData);
    const [Propdata, SetPropData] = useState(props.data);
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }, 6000);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    useEffect(() => {
        SetPropData(props.data);
        try {
            if (props.data[0].SubTitle === 'Signature has expired.' || props.data[0].SubTitle === 'Invalid Token!') {
                cleardata()
            }
        } catch (error) {

        }
    }, [props?.data[0]?.SubTitle]);

    if (currentIndex >= Propdata.length) {
        return null;
    }
    const cleardata = () => {
        localStorage.setItem(`UserData`, JSON.stringify({ "provider": "" }))
        dispatch(SetUserData({ "provider": "" }))
        dispatch(SetProfileStatus());
    }
    const capitalizeFirstLetter = (inputString) => {
        if (typeof inputString !== 'string') return inputString;
        inputString = inputString?.trim();
        return `${inputString.charAt(0).toUpperCase()}${inputString.slice(1).toLowerCase()}`;
    };

    const a = Propdata[currentIndex];
    const Healper = (data, Provide) => {
        return data === 'Success'
            ? Provide === 'icon'
                ? Success
                : Provide === 'style'
                    ? 'linear-gradient(to right, #18f888 -82.5%, #1a2228 33.7%)'
                    : Provide === 'text'
                        ? '#16F986'
                        : ''
            : data === 'Error'
                ? Provide === 'icon'
                    ? Error
                    : Provide === 'style'
                        ? 'linear-gradient(to right, #ed1e51 -82.5%, #1a2228 35.67%)'
                        : Provide === 'text'
                            ? '#ED1E51'
                            : ''
                : Provide === 'icon'
                    ? Alert
                    : Provide === 'style'
                        ? 'linear-gradient(to right, #ed501e -82.5%, #1a2228 35.67%)'
                        : Provide === 'text'
                            ? '#ED501E'
                            : '';
    };

    return (
        <>
            {Propdata?.length > 0 &&
                <div className="left-0 flex items-center justify-center z-20 fixed w-full top-[15%] Def-font">
                    <div className="absolute flex items-center justify-center z-89 popup-animation">
                        <div className="w-auto">
                            <div
                                className="p-[17px] md:p-[20px] lg:p-[24px] h-[75px] md:h-[85px] lg:h-[95px] rounded-lg center_aline"
                                style={{ background: Healper(a?.Styling.Icon, 'style') }}
                            >
                                <div className="pr-[20px] md:pr-[25px] lg:pr-[30px]">
                                    <img className="" src={Healper(a?.Styling.Icon, 'icon')} alt="Icon" />
                                </div>
                                <div>
                                    <p
                                        className="text-sm md:text-md lg:text-lg font-medium text-left"
                                        style={{ color: Healper(a?.Styling.Icon, 'text') }}
                                    >
                                        {a?.Title && a?.Title === 'error' ? 'Please login' : capitalizeFirstLetter(a?.Title.split('_').join(' '))}
                                    </p>
                                    <p className="text-[0.5rem] md:text-[0.9rem] lg:text-base text-left text-[#bbb]">
                                        {a?.SubTitle == 'Invalid Token!' ? 'Please login to your account' : a?.SubTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PopUp;
