import React, { useState, useEffect, useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch } from 'react-redux';
import { StoreLanguage } from '../../../src/components/Store/index';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Rinput.css';

const Rinput = (prop) => {
    const needtorestrict = ['Description', 'Additional Details'];
    const dispatch = useDispatch();
    const [dissable, setdissable] = useState(false);
    const [inputdata, setinputdata] = useState(prop.data?.defaultValue);
    const [recommendedOptions, setRecommendedOptions] = useState(prop.dropDown);
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleNumericInput = (evt) => {
        if (prop.data.Type === 'number' && ["e", "E", "+", "-"].includes(evt.key)) {
            evt.preventDefault();
        }
        if ((evt.key === 'Tab' || evt.key === 'Enter') && dropdownRef.current) {
            setdissable(!dissable);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Close the dropdown when Enter is pressed
            prop?.onToggle(false);
        }
    };

    useEffect(() => {
        if (prop.data?.defaultValue && prop.data?.defaultValue.length) {
            setinputdata(prop.data?.defaultValue);
        }
    }, [prop.data]);

    const CustomDatePickerInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            onChange={Inputdata}
            placeholder='Date'
            readOnly
            className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] h-[71px] pr-[20px] text-white Def-font text-lg rounded-[10px] 123"
        />
    );

    useEffect(() => {
        if (prop.data.Holder === 'Description' && inputdata?.length > 250) {
            // Handle validation for Description length
        }
    }, [inputdata]);

    const Inputdata = (event, Slected, propdata, SkipIvalidation) => {
        let inputValue = event?.target?.value?.toString();
        if (SkipIvalidation) {
            inputValue = event;
        }
        if (!inputValue && (typeof event === "string" || typeof event === 'number')) {
            inputValue = event;
        } else if (event?.target?.type === 'checkbox') {
            inputValue = event.target.checked;
        }
        setinputdata(inputValue);

        if (prop?.dropDown?.length) {
            if (inputValue) {
                setdissable(true);
                const filteredOptions = prop.dropDown.filter(option =>
                    String(option).toLowerCase().startsWith(String(inputValue).toLowerCase()) // Filter by starting letters
                );
                setRecommendedOptions(filteredOptions);
            } else {
                setdissable(false);
                setRecommendedOptions([]);
            }
        }

        if (prop.data.mustSlected) {
            if (Slected) {
                inputValue = event;
                prop.Rinputdata({
                    UserEnterData: inputValue,
                    Name: prop.data.tags || prop.data.Holder,
                    showPopup: 'Slected',
                });
                setdissable(false);
                return;
            } else {
                prop.Rinputdata({
                    UserEnterData: '',
                    Name: prop.data.tags || prop.data.Holder,
                    showPopup: 'NeedToSlected',
                });
                return;
            }
        }

        prop.Rinputdata({
            UserEnterData: inputValue,
            Name: prop.data.tags || prop.data.Holder,
        });
    };

    const handleOptionClick = (option) => {
        Inputdata(option, 'from');
        setdissable(false);
        setRecommendedOptions([]);
    };

    const togglePasswordVisibility = () => {
        setdissable(!dissable);
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (selectedDate) {
            Inputdata(selectedDate, undefined, undefined, 'skip');
        }
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setdissable(false);
                setRecommendedOptions([]);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const onSelect = (selectedList, selectedItem) => {
        prop.Rinputdata({
            UserEnterData: true,
            Name: selectedItem.tags
        });
    };

    const onRemove = (selectedList, removedItem) => {
        let data = prop?.dropDown.filter(a => a.title === removedItem.title);
        prop.Rinputdata({
            UserEnterData: false,
            Name: data[0].tags
        });
    };

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);

    return (
        <>
            {!prop.data.features && (
                <div ref={inputRef} className="h-[115px] pt-[15px] rounded-b-[10px]">
                    {prop.data.Type === 'checkbox' ? (
                        <>
                            <div className='w-full h-[70px] flex bg-white/[0.15] items-center border border-[#bbb] border-solid rounded-lg text-white Def-font text-lg mt-[30px]'>
                                <input
                                    className='mx-[2%]'
                                    type="checkbox"
                                    value={inputdata}
                                    style={{ width: '30px', height: '30px' }}
                                    onChange={Inputdata}
                                    checked={prop.data.Holder}
                                />
                                <label className=''>{prop.data.Holder}</label>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex text-white p-1 pl-[10px]'>
                                {prop.data.Holder} {prop.data.important ? ' *' : ''} {needtorestrict.includes(prop.data.Holder) ? `( ${inputdata?.length ? inputdata?.length : 0} / 250 )` : ''}
                            </div>
                            <div className='h-[70px] relative'>
                                <div className='h-full w-full'>
                                    {
                                        prop?.data?.Type === 'date' && prop?.data?.from ? (
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                customInput={<CustomDatePickerInput />}
                                                placeholderText={prop?.data?.placeHolder ? prop?.data?.placeHolder : prop?.data?.Holder}
                                                onWheel={(event) => event.currentTarget.blur()}
                                                onKeyDown={handleNumericInput}
                                                disabled={prop?.data?.placeHolder?.includes('Select')}
                                                maxDate={maxDate}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        ) : (
                                            <input
                                                type={showPassword ? 'text' : (prop.data.Type ? prop.data.Type : 'password')}
                                                className="outline-none w-full h-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] text-white Def-font text-lg rounded-[10px]"
                                                placeholder={prop?.data?.placeHolder ? prop?.data?.placeHolder : prop?.data?.Holder}
                                                onChange={Inputdata}
                                                value={inputdata}
                                                onWheel={event => event.currentTarget.blur()}
                                                onKeyDown={handleNumericInput}
                                                disabled={prop?.data?.placeHolder?.includes('Select')}
                                                maxLength={needtorestrict.includes(prop.data.Holder) ? 250 : 100000}
                                            />
                                        )
                                    }
                                    {prop?.data?.placeHolder?.includes('Select') && (
                                        <div className="outline-none absolute w-full h-full pl-[20px] pr-[20px] Def-font text-lg rounded-[10px] top-0 cursor-pointer" onClick={() => prop?.data?.placeHolder?.includes('Select') && togglePasswordVisibility()}>
                                            {/* Additional content for the select case */}
                                        </div>
                                    )}
                                </div>

                                {prop.data.Icon && (
                                    <img
                                        onClick={togglePasswordVisibility}
                                        src={!showPassword ? prop.data.Icon : prop.data.Clear}
                                        className="absolute top-5 right-4 transform translate_y_1 w-8 h-[21px] rounded object-none object_fit cursor-pointer"
                                        alt="Toggle Password Visibility"
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            {!prop.data.features && (
                prop?.dropDown?.length > 0 && dissable ? (
                    <div ref={dropdownRef} className="absolute w-[90%] bg-[#BBBBBB] min-h-0 max-h-[300px] overflow-auto z-[11] mt-3 rounded-md custom-scrollbar">
                        <div className='flex justify-center flex-col mx-[5%]'>
                            {recommendedOptions.length > 0 ? (
                                recommendedOptions.map((option) => (
                                    <div key={option} onClick={() => handleOptionClick(option)} className='w-[100%] h-[50px] border-b border-[#666666]'>
                                        <div className='h-full w-full flex justify-center items-center cursor-pointer'>
                                            {option}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                prop?.dropDown?.map((option) => (
                                    <div key={option} onClick={() => handleOptionClick(option)} className='w-[100%] h-[50px] border-b border-[#666666]'>
                                        <div className='h-full w-full flex justify-center items-center cursor-pointer'>
                                            {option}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                ) : null
            )}
            {prop.data.features && <div className='pt-[15px]'>
                <div className='flex text-white p-1 pl-[10px]'>
                    {prop.data.Holder} {prop.data.important ? ' *' : ''}
                </div>
                <Multiselect
                    options={prop?.dropDown?.map((a, i) => ({ ...a, id: i }))}
                    selectedValues={prop?.data?.defaultValue?.map((a, i) => ({ title: a, id: i }))}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    onKeyDown={handleKeyDown}
                    displayValue="title"
                    emptyRecordMsg='No Data'
                    showArrow={true}
                    customArrow={<img
                        src={prop.data.Icon}
                        className="w-8 h-[21px]"
                    />}
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={false}
                    singleSelect={prop?.data?.slectOne ? true : false}
                />
            </div>}
        </>
    );
};

export default Rinput;
