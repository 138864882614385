import React, { useState, useEffect } from 'react';
import star from '../../assets/star.svg';
import Down_Aerrow from '../../assets/Down_Aerrow.svg';
import Menu from '../../assets/Menu.svg';
import EmptyScreen from '../Helper/EmptyScreen';
import XMark from '../../assets/XMark.svg';
import defaultimage from '../../assets/DefaultImage.svg';
import AddFile from '../../assets/AddData.svg';
import PriceRangeSlider from '../Helper/PriceSlide';
import { GetAllCars, GetUserAllCars, CreateBookmarks } from '../../server/index';
import BookMark from '../../assets/BookMark.svg';
import SlectedBookmark from '../../assets/SelectedBookMark.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetAddNewPost, SetBookMark, setProps, StoreLanguage, SetApiUrlStructure, SetTabData } from '../Store/index';
import Cards from '../Helper/cards';
import { SideFilter } from '../../server/index';
import './indexStyle.css';

function Cservices(prop) {
    const [fiterdata, setfiterdata] = useState(prop.data.filter);
    const ReminingData = useSelector(state => state.storeContent.AddNewPost);
    let BookMrkData = useSelector(state => state.storeContent.BookMrkData);
    const profiles = useSelector(state => state.storeContent.UserData);
    const dataStatus = useSelector(state => state.storeContent.DataStatus);
    const [CarsData, SetcarsData] = useState(prop.data.CardData.Cards);
    const [userdata, setuserData] = useState([]);
    const [HideMore, setHideMore] = useState(false);
    const [CarsRawData, SetcarsRawData] = useState([]);
    const [propData, SetpropData] = useState(prop.data);
    const [invese, setinverse] = useState(false);
    const [Tabdata, Settabdata] = useState('Buy');
    const [Filter, setFilter] = useState([]);
    const [APIStructure, setAPIStructure] = useState([]);
    const [TabsData, SetTabsData] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [StageData, setStageData] = useState(null);
    const [sorttoggle, Setsorttoggle] = useState(false);
    const [SortedValue, setSortedValue] = useState('');
    const [loading, setLoading] = useState(false); // Loading state for showing loader
    const dispatch = useDispatch();
    const location = useLocation();
    const [filtercounter, setFiltercounter] = useState({});

    useEffect(() => {
        setfiterdata(prop.data.filter);
        filterdata();
    }, [prop.data.filter]);

    useEffect(() => {
        Settabdata(location.pathname.split('/').includes('sell') ? 'Sell' : 'Buy');
    }, [location]);

    useEffect(() => {
        let searchParams = new URLSearchParams(APIStructure);
        ["price_min", "max", "min_price", "max_price"].forEach(param => searchParams.delete(param));
        searchParams = decodeURIComponent(searchParams).toString();
        if (searchParams && APIStructure && typeof APIStructure === 'string') {
            const result = searchParams.split('&').reduce((result, keyValue) => {
                const [key, value] = keyValue.split('=');
                if (!result[key]) {
                    result[key] = [];
                }
                result[key].push(...value.split(',').filter(a => a !== ''));
                return result;
            }, {});
            setFiltercounter(result);
        }
    }, [APIStructure]);

    const Toggle = async (prop) => {
        Settabdata(prop === 'Buy' ? 'Buy' : 'Sell');
    };

    useEffect(() => {
        if (Tabdata) {
            localStorage.setItem(`TabData`, Tabdata);
            dispatch(SetTabData(Tabdata));
        }
    }, [Tabdata]);

    useEffect(() => {
        if (!Tabdata) {
            dispatch(SetTabData(localStorage.getItem(`TabData`)));
        }
    }, []);

    const customSort = (arr) => {
        return arr.sort(function (a, b) {
            if (typeof a === 'number' && typeof b === 'number') {
                return a - b;
            } else {
                return String(a).localeCompare(String(b));
            }
        });
    };

    const splitVehicleInfo = (info) => {
        const brands = Object.keys(info);
        const models = [];
        const modelVariations = [];

        for (const brand in info) {
            const brandModels = Object.keys(info[brand]);

            for (const model of brandModels) {
                const variations = info[brand][model];
                models.push(model);
                modelVariations.push(...variations);
            }
        }

        return [brands, models, modelVariations];
    };

    const filterdata = async () => {
        setLoading(true);  // Start loading
        try {
            const { data } = await SideFilter();
            setFilter(data);
            const [brands, models, modelVariations] = splitVehicleInfo(data.vehicle_car_info);
            DataModification(brands, models, modelVariations, data);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const DataModification = (brands, models, modelVariations, types) => {
        let localData = [
            {
                SubTitle: customSort(brands),
                SubTitleDum: customSort(brands),
                tag: 'brand'
            },
            {
                SubTitle: customSort(models),
                SubTitleDum: customSort(models),
                tag: 'model'
            },
            {
                SubTitle: customSort(modelVariations),
                SubTitleDum: customSort(modelVariations),
                tag: 'varient'
            },
            // {
            //     SubTitle: [1, 10000000],
            //     tag: 'min_price & max_price'
            // },
            {
                SubTitle: [
                    {
                        title: 'Under 10K',
                        tag: 'min_price=1&max_price=10000'
                    },
                    {
                        title: '10K To 20K',
                        tag: 'min_price=10000&max_price=20000'
                    },
                    {
                        title: '20K To 30K',
                        tag: 'min_price=20000&max_price=30000'
                    },
                    {
                        title: '30K To 40K',
                        tag: 'min_price=30000&max_price=40000'
                    },
                    {
                        title: '40K To above',
                        tag: 'min_price=40000&max_price=10000000000'
                    }
                ],
                MoreOption: [10000, 1000000],
                tag: 'min_price & max_price'
            },
            {
                SubTitle: customSort(types.vehicle_car_types),
                SubTitleDum: customSort(types.vehicle_car_types),
                tag: 'type'
            },
            {
                SubTitle: types?.fuel_type,
                SubTitleDum: types?.fuel_type,
                tag: 'fuel_type'
            },
            {
                SubTitle: ['0 - 30', '30 - 50', '50 - 80', '80 and above'],
                SubTitleDum: ['0 - 30', '30 - 50', '50 - 80', '80 and above'],
                tag: 'mileage'
            },
            {
                SubTitle: customSort(types.transmission_type),
                SubTitleDum: customSort(types.transmission_type),
                tag: 'transmission_type'
            },
            {
                SubTitle: ['0 - 1000',
                    '1000 - 1500',
                    '1500 - 2500',
                    '2500 - 3500',
                    '3500 and above'],
                SubTitleDum: ['0 - 1000',
                    '1000 - 1500',
                    '1500 - 2500',
                    '2500 - 3500',
                    '3500 and above'],
                tag: 'engine_sizes'
            },
            {
                SubTitle: [],
                SubTitleDum: [],
                tag: 'min_no_of_airbags & max_no_of_airbags'
            },
            {
                SubTitle: types?.vehicle_ratings.map(a => `Rating ${a}`),
                SubTitleDum: types?.vehicle_ratings.map(a => `Rating ${a}`),
                tag: 'overall_rating',
                Orginaldata: types?.vehicle_ratings,
            },
            {
                SubTitle: ['sold', 'forsale'],
                SubTitleDum: ['sold', 'forsale'],
                tag: 'car_status'
            }
        ]
        const filteredData = prop.data.filter.map(a => {
            const matchingData = localData.find(b => b.tag === a.tag);
            return matchingData ? {
                title: a.title,
                logo: a.logo,
                Unable: false,
                searchBar: a?.searchBar ? true : false,
                searchText: a.searchText,
                ...matchingData
            } : null;
        }).filter(item => item !== null);
        setTabs(filteredData);
    };
    // useEffect(() => {
    // Settabdata(location.pathname.split('/').includes('sell') ? 'Sell' : 'Buy')
    // if (Filter.length === 0) {
    //     filterdata()
    // }
    // if (profiles?.Authorization && !CarsRawData.length) {
    //     GetCars()
    // } else if (!CarsRawData.length) {
    //     GetCars()
    // }
    // }, [profiles]);
    useEffect(() => {
        Settabdata(location.pathname.split('/').includes('sell') ? 'Sell' : 'Buy');
        // if (!Filter.vehicle_car_info) {
        //     filterdata()
        // }
        GetCars(APIStructure);
    }, [profiles]);
    // useEffect(() => {
    //     if (!Filter.vehicle_car_info) {
    //         filterdata()
    //     }
    // }, []);
    useEffect(() => {
        SetcarsData(prop.data.CardData.Cards);
        SetpropData(prop.data);
    }, [prop.data]);

    useEffect(() => {
        const priceData = ['0 & 0K', '0 & 0', '0k & 0', '0K & 0K'];
        if (TabsData.filter(b => priceData.includes(b.Show)).length) {
            SetTabsData(TabsData.filter(a => !priceData.includes(a.Show)));
        }
    }, [TabsData]);

    const SwitchNewpost = () => {
        if (profiles?.Authorization) {
            dispatch(SetAddNewPost(!ReminingData));
        } else {
            dispatch(setProps([propData?.PopUpData?.ErrorMessage?.NoticeUserToLogin]));
        }
    };

    const ShowDropDown = (props) => {
        setTabs(
            tabs.map((tab) =>
                tab.title === props.title && !props.Unable ? { ...tab, Unable: true } : { ...tab, Unable: false }
            )
        );
    };

    const UnCode = (props, Alldata, index, sort = false) => {
        const newTabs = tabs.map((tab) => ({ ...tab, Unable: false }));
        setTabs(newTabs);
        if (Alldata.tag === 'min_price & max_price') {
            setHideMore(false)
            let data = { ...StageData }
            delete data.price
            setStageData({ ...data, 'price_min&max': [props.tag] });
            SetTabsData((prevTabsData) => [...prevTabsData.filter(b => b.key !== 'price_min&max'), { 'Show': props.title, 'key': `price_min&max` }])
            return
        }
        if (Alldata?.tag === "brand") {
            const newFilter = { ...Filter, [props]: Filter.vehicle_car_info[props] };
            setFilter(newFilter);
            let AllData = {};
            for (let filt in Filter.vehicle_car_info) {
                if ([...TabsData.map(a => a.Show), props].includes(filt)) {
                    AllData[filt] = Filter.vehicle_car_info[filt];
                } else {
                    AllData[filt] = {};
                }
            }
            const [brands, models, modelVariations] = splitVehicleInfo({ ...AllData });
            DataModification(brands, models, modelVariations, { ...Filter, ...AllData });
        }
        const renderdata = (Alldata?.Orginaldata && Alldata.Orginaldata.length)
            ? Alldata.Orginaldata[index]
            : props;
        setinverse(!invese);
        if (!sort) {
            SetTabsData(prevTabsData => [...prevTabsData, { 'Show': props, 'key': `${Alldata.tag}` }]);
        }
        if (StageData && Object.keys(StageData)?.length) {
            setStageData(prevStageData => ({
                ...prevStageData,
                [Alldata.tag]: [...(prevStageData[Alldata.tag] || []), renderdata],
            }));
        } else {
            setStageData({ [Alldata.tag]: [renderdata] });
        }
        if (sort) {
            if (props) setSortedValue(sort);
            Setsorttoggle(!sorttoggle);
        }
    };

    useEffect(() => {
        if (StageData) {
            setAPIStructure(Object.entries(StageData)
                .map(([key, value]) => {
                    let temp = value[value.length - 1];
                    if (Array.isArray(value)) {
                        value = value.join(',');
                    }
                    if (temp === 'desc') {
                        return `${key}=${[...new Set(value.split(','))].filter(a => a !== 'asc').join('&price=')}`;
                    }
                    if (temp === 'asc') {
                        return `${key}=${[...new Set(value.split(','))].filter(a => a !== 'desc').join('&price=')}`;
                    }
                    return `${key}=${[...new Set(value.split(','))].join(',')}`;
                })
                .join('&'));
        }
    }, [StageData]);

    useEffect(() => {
        if (APIStructure && APIStructure.length) {
            dispatch(SetApiUrlStructure(APIStructure));
            GetCars(APIStructure);
        }
    }, [APIStructure]);

    const [inputData, setInputData] = useState(null);
    useEffect(() => {
        if (inputData) {
            setHideMore(false);
            let data = { ...StageData };
            delete data.price;
            setStageData({
                ...data,
                'price_min&max': [
                    `min_price=${inputData.x ? inputData.x : 0}&max_price=${inputData.y ? inputData.y : 100000000000
                    }`,
                ],
            });
            SetTabsData((prevTabsData) => [
                ...prevTabsData.filter((b) => b.key !== 'price_min&max'),
                {
                    Show: `${inputData.x ? inputData.x + 'K' : 0} To ${inputData.y ? inputData.y + 'K' : 0
                        }`,
                    key: 'price_min&max',
                },
            ]);
        }
    }, [inputData]);

    const inputField = (event, field) => {
        setInputData((prevInputData) => ({
            ...prevInputData,
            [field]: event.target.value,
        }));
    };

    const ClearData = () => {
        GetCars();
        SetTabsData([]);
        setTabs([]);
        setStageData({});
        setAPIStructure([]);
        setFiltercounter({});
        const [brands, models, modelVariations] = splitVehicleInfo(Filter.vehicle_car_info);
        DataModification(brands, models, modelVariations, Filter);
    };

    const ClearSingle = (props) => {
        if (props.key === "brand") {
            // setFilter(Filter)
            // const [brands, models, modelVariations] = splitVehicleInfo(Filter.vehicle_car_info);
            // DataModification(brands, models, modelVariations, Filter)
            const newFilter = { ...Filter };
            let AllData = {};
            for (let filt in Filter.vehicle_car_info) {
                if ([...TabsData.map(a => a.Show).filter(a => a !== props.Show)].includes(filt)) {
                    AllData[filt] = Filter.vehicle_car_info[filt];
                } else {
                    AllData[filt] = {};
                }
            }
            const [brands, models, modelVariations] = splitVehicleInfo({ ...AllData });
            if (!models.length) {
                const [brands, models, modelVariations] = splitVehicleInfo({ ...newFilter.vehicle_car_info });
                DataModification(brands, models, modelVariations, { ...newFilter });
            } else {
                DataModification(brands, models, modelVariations, { ...Filter, ...AllData });
            }
        }
        let ReStructure = {}
        let Coppy = { ...StageData }
        if (props.key === 'price') {
            delete Coppy.price
            ReStructure = { ...Coppy, [props.key]: [] }
        } else if (props.key === 'overall_rating') {
            ReStructure = { ...Coppy, [props.key]: Coppy[props.key].filter(a => a !== parseInt(props.Show.match(/\d+/)[0])) }
        } else if (props.key === 'price_min&max') {
            ReStructure = { ...Coppy, [props.key]: [] }
            setInputData(null)
            // setInputData({
            //     "x": "",
            //     "y": ""
            // })
        } else {
            ReStructure = { ...StageData, [props.key]: StageData[props.key].filter(a => a !== props.Show) }
        }
        setStageData(ReStructure, TabsData.filter(name => name.Show !== props.Show))
        SetTabsData(TabsData.filter(name => name.Show !== props.Show))
    }

    const GetCars = async (URL) => {
        setLoading(true);  // Start loading
        try {
            const { data } = await GetAllCars(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, (URL && URL.length) ? `?limit=20&${URL.replace(/price_min&max=m/g, 'm').replace(/ - /g, ',').replace(/ and /g, ',')}` : '?limit=20');
            SetcarsRawData(data.results);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const handleApiError = (error) => {
        if (error?.response?.status === 500) {
            dispatch(StoreLanguage.setProps([{
                Title: '500 server error',
                SubTitle: '500 server error',
                Styling: {
                    Icon: 'Error',
                }
            }]));
        } else {
            let temp = [];
            for (let er in error?.response?.data) {
                temp.push({
                    Title: er,
                    SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                    Styling: {
                        Icon: 'Error',
                    }
                });
            }
            dispatch(StoreLanguage.setProps(temp));
        }
    };

    const currencies = JSON.parse(localStorage.getItem(`Cur`));
    const checktitle = (data) => {
        if (data === 'sold') {
            return 'Sold';
        } else if (data === 'forsale') {
            return 'Available';
        }
        return data;
    };

    useEffect(() => {
        if (!profiles && !profiles?.name) {
            dispatch(setProps([propData?.PopUpData?.ErrorMessage?.NoticeUserToLogin]));
            return;
        }
        if (BookMrkData && BookMrkData.length) {

        } else {
            let Rawdata = JSON.parse(localStorage.getItem(`${profiles.name}_BookMrkData`));
            if (Rawdata && Rawdata.length) {
                dispatch(SetBookMark(Rawdata));
            }
        }
    }, [CarsData]);

    const Getusercar = async () => {
        setLoading(true);  // Start loading
        if (profiles?.id) {
            try {
                const { data } = await GetUserAllCars({
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    }
                }, profiles?.id);
                setuserData(data);
                setcount(0);
            } catch (error) {
                handleApiError(error);
            } finally {
                setLoading(false);  // Stop loading
            }
        }
    };

    useEffect(() => {
        Getusercar(profiles);
    }, [profiles, dataStatus]);

    const AddToBookMark = async (props) => {
        setLoading(true);  // Start loading
        try {
            await CreateBookmarks(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                "car_vehicle": props.id
            });
            GetCars(APIStructure);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const SortBy = (props) => {
        if (props) setSortedValue(props);
        Setsorttoggle(!sorttoggle);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    const [count, setcount] = useState(40);
    const openProductInNewTab = (props) => {
        window.open(`${window.location.origin}/product-detail/${props}`, '_blank');
    };

    const onsubmit = async () => {
        setLoading(true);  // Start loading
        try {
            const { data } = await GetAllCars(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, (APIStructure && APIStructure.length) ? `?limit=${count}&${APIStructure.replace('price=m', 'm')}` : `?limit=${count}`);
            SetcarsRawData(data.results);
            setcount(count + 20);
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const setSearchInput = (inputdata, DataArray, dropdownData, colpeatObj) => {
        const filterCriteria = inputdata?.toLowerCase();
        const filteredData = DataArray.filter((item) => {
            return item.toLowerCase().includes(filterCriteria);
        });
        setTabs(colpeatObj.map(a => a.title === dropdownData.title ? { ...dropdownData, SubTitleDum: filteredData.length ? filteredData : dropdownData.SubTitle } : a));
    };

    const setprice = (props) => {
        setHideMore(false);
        let data = { ...StageData };
        delete data.price;
        setStageData({ ...data, 'price_min&max': [`min_price=${props[0]}&max_price=${props[1]}`] });
        SetTabsData((prevTabsData) => [...prevTabsData.filter(b => b.key !== 'price_min&max'), { 'Show': `${currencies.symbol}${props[0]} To ${currencies.symbol}${props[1]}`, 'key': `price_min&max` }]);
        setTabs(tabs.map((tab) => ({ ...tab, Unable: false })));
    };
    return (
        <>
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="loader"></div>
                </div>
            )}
            <div className='Def-font'>
                <div className="w-full flex justify-center py-[20px]">
                    <div className="w-[215px] h-[45px] rounded-[30px] bg-white/[0.08] border border-[#666] flex items-center justify-center" style={{ boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.15)" }}>
                        <div onClick={() => Toggle('Buy')} className={`cursor-pointer w-[100px] h-[35px] rounded-[30px] flex items-center justify-center`} style={{ background: Tabdata === 'Buy' && "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                            <p className={`text-base text-center uppercase text-[#333]  ${Tabdata !== 'Buy' && 'text-white'}`}>{prop?.data?.productCards?.buy?.title}</p>
                        </div>
                        <div onClick={() => Toggle('Sell')} className={`cursor-pointer w-[100px] h-[35px] rounded-[30px] flex items-center justify-center`} style={{ background: Tabdata === 'Sell' && "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}>
                            <p className={`text-base text-left uppercase text-[#333]" ${Tabdata !== 'Sell' && 'text-white'}`}>
                                {prop?.data?.productCards?.sell?.title}
                            </p>
                        </div>
                        <div className='p-[23px] visible md:invisible lg:invisible fixed top-0 right-[4%] cursor-pointer z-[11]' onClick={() => setinverse(!invese)}>
                            <img src={Menu} className="w-[16px] h-[16px]" alt="logo" />
                        </div>
                    </div>
                </div>
                {invese && <div className='w-[20px] h-[20px] rounded-full  bg-white absolute top-[15%] right-8 cursor-pointer block md:hidden lg:hidden' onClick={() => setinverse(!invese)}>x</div>}
                {Tabdata === 'Buy' && <div className="flex items-center justify-center flex-col h-auto min-h-full" >
                    <div className="w-full md:w-[95%] lg:w-[90%] flex">
                        <div className={`w-full md:w-[250px] lg:w-[330px] h-auto fixed bg-zinc-700 md:relative md:bg-transparent lg:relative z-10 lg:bg-transparent ${!invese ? 'invisible' : 'visible'} md:visible lg:visible`}>
                            <div className="w-full md:w-[250px] lg:w-[330px] h-auto rounded-[20px] bg-[#ffffff26] border border-[#aeb2b6]" style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}>
                                <div className='w-full md:w-[250px] lg:w-[330px] h-auto flex items-center flex-col'>
                                    {tabs.map((a, index) => (
                                        <div className={tabs.length === index + 1 ? 'w-full' : 'border-b border-[#aeb2b6] w-full'}>
                                            <div key={index} className="w-full h-[80px] flex items-center justify-center cursor-pointer" onClick={() => ShowDropDown(a)}>
                                                <p className="pl-5 text-base font-medium text-left text-white w-[90%] flex items-center justify-between">
                                                    {a.title}{filtercounter[a.tag]?.length > 0 && <div className='w-5 h-5 bg-gradient-to-r from-[#FFBE41] to-[#FF9334] rounded-full text-sm mr-5 flex justify-center items-center'>{filtercounter[a.tag]?.length}</div>}
                                                </p>
                                                <img src={a.logo} className="w-[16px] h-[16px] mr-5" alt="logo" />
                                            </div>
                                            {(a.tag !== 'min_price & max_price' && a.Unable) &&
                                                <div className='bg-[#bbbbbb63] rounded-b-lg z-8 min-h-0 max-h-[300px] overflow-auto w-full'>
                                                    {a.searchBar && <div className='w-full flex items-center justify-center p-[4px]'>
                                                        <div className="relative w-[90%]">
                                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                                            </div>
                                                            <input type="text" id="simple-search" className="bg-[#4D5763] border-gray-300 Def-font text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-white" placeholder={a.searchText} required onChange={(e) => setSearchInput(e.target.value, a.SubTitleDum, a, tabs)} />
                                                        </div>
                                                    </div>}
                                                    {a.SubTitleDum?.length > 0 &&
                                                        a.SubTitleDum.map((b, index) => (
                                                            <div className='w-full flex justify-center cursor-pointer'>
                                                                <p className="text-base font-medium text-white text-center border-b border-[#aeb2b6] border-[90%] py-2 w-[90%]" style={{ borderBottom: '5% solid' }} onClick={() => UnCode(b, a, index)}>
                                                                    {checktitle(b)}
                                                                </p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {/* {(a.tag === 'min_price & max_price' && a.Unable) &&
                                                <div className='w-[99%] bg-gray-300 rounded-b-lg z-8 min-h-0 max-h-[300px] overflow-auto'>
                                                    <PriceRangeSlider Data={a} MInAndMax={setprice} />
                                                </div>
                                            }  */}
                                            <div className='bg-[#bbbbbb63] rounded-b-lg z-8 min-h-0 max-h-[300px] overflow-auto w-full'>
                                                {(a.tag === 'min_price & max_price' && a.Unable) &&
                                                    <>
                                                        <div className='w-[99%] rounded-b-lg z-8 min-h-0 max-h-[300px] overflow-auto'>
                                                            <div className='w-full flex justify-center items-center'>
                                                                <div className='flex w-[90%] border-b border-[#aeb2b6] border-[90%] py-2'>
                                                                    <div className='flex justify-between items-center'>
                                                                        <div className='w-[45%] flex items-center'>
                                                                            <input className='w-full rounded-md h-[25px] px-2' type='number' placeholder='min' value={inputData?.x || ''} onWheel={(e) => e.preventDefault()} onChange={(e) => inputField(e, 'x', a, index)} /><span className='text-base font-medium text-white text-center py-2 pl-2'>K</span>
                                                                        </div>
                                                                        <div className='w-[45%] flex items-center'>
                                                                            <input className='w-full rounded-md h-[25px] px-2' type='number' placeholder='max' value={inputData?.y || ''} onWheel={(e) => e.preventDefault()} onChange={(e) => inputField(e, 'y', a, index)} /><span className='text-base font-medium text-white text-center py-2 pl-2'>K</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {a.SubTitle && a.SubTitle?.length > 0 &&
                                                                a.SubTitle.map((b, index) => (
                                                                    <div className='w-full flex justify-center cursor-pointer'>
                                                                        <p className="text-base font-medium text-white text-center border-b border-[#aeb2b6] border-[90%] py-2 w-[90%]" style={{ borderBottom: '5% solid' }} onClick={() => UnCode(b, a, index)}>
                                                                            {b.title}
                                                                        </p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        {/* {!HideMore && <div className='w-full flex justify-center cursor-pointer'>
                                                            <p className="text-base font-medium text-white text-center border-b border-[#aeb2b6] border-[90%] py-2 w-[90%]" style={{ borderBottom: '5% solid' }} onClick={() => setHideMore(true)}>
                                                                More...
                                                            </p>
                                                        </div>} */}
                                                        {HideMore && <div className='pt-3'>
                                                            <PriceRangeSlider Data={a} MInAndMax={setprice} />
                                                        </div>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className='w-full'>
                            <>
                                <div className="h-auto md:mb-6 lg:mb-[40px] flex flex-row m-0 lg:ml-5">
                                    {TabsData.length > 0 && (
                                        <div className="w-full flex flex-wrap">
                                            {TabsData.filter((item, index, self) =>
                                                index === self.findIndex(t => t.Show === item.Show)
                                            ).map((a, index) => (
                                                <div
                                                    key={index}
                                                    className="h-[38px] flex justify-center items-center ml-5 mb-4"
                                                >
                                                    <div
                                                        className="h-[38px] rounded-[10px] bg-[#bbb] flex justify-center items-center"
                                                        style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                                                    >
                                                        <div className="w-auto h-[17.49px] flex justify-center items-center px-4">
                                                            <p className="text-sm font-medium text-left text-[#2d3136] pr-[10px]">
                                                                {checktitle(a.Show)}
                                                            </p>
                                                            <img src={XMark} className="w-[10px] h-[10px] cursor-pointer" alt="logo" onClick={() => ClearSingle(a)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='h-[38px] flex justify-center items-center ml-5 mb-4 cursor-pointer' onClick={() => ClearData()}>
                                                <p className="text-sm font-medium text-left text-[#bbb]">
                                                    Clear
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {TabsData.length === 0 && (<div className="w-full flex items-center"></div>)}
                                    <div className='flex justify-end invisible md:visible lg:visible'>
                                        <div className="w-[265px] h-[45px]">
                                            <div className="w-[265px] h-[45px] opacity-[0.35] rounded-[10px] bg-[#ffffff26] border border-white flex justify-center items-center cursor-pointer" style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }} onClick={() => SortBy()} >
                                                <div className="w-[225px] h-[19px] flex items-center">
                                                    <p className="text-base text-left text-[#bbb] w-[90%]">{SortedValue ? SortedValue : prop?.data?.productCards?.SortBy}</p>
                                                    <img src={Down_Aerrow} className="w-[16px] h-[16px]" alt="logo" />
                                                </div>
                                            </div>
                                            {sorttoggle &&
                                                <div className='relative'>
                                                    <div className='bg-[#BBBBBB] rounded-lg z-10 absolute w-full'>
                                                        <p className="text-base font-medium text-black text-center border-b border-gray-500 py-2 cursor-pointer w-[90%] mx-[5%]" onClick={() => UnCode('desc', { tag: "price" }, 0, 'Price - High to Low')}>
                                                            Price - High to Low
                                                        </p>
                                                        <p className="text-base font-medium text-black w-full text-center border-gray-500 py-2 cursor-pointer" onClick={() => UnCode('asc', { tag: "price" }, 1, 'Price - Low to High')}>
                                                            Price - Low to High
                                                        </p>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </>
                            {
                                (!CarsRawData || CarsRawData.length === 0) && <div className='w-full flex justify-center'>
                                    <EmptyScreen />
                                </div>
                            }
                            {
                                (CarsRawData && CarsRawData.length !== 0) && CarsRawData?.map((a, i) => (
                                    <div className="lg:flex rounded-md bg-white m-5 md:m-0 lg:m-0 md:my-5 lg:my-[30px] md:ml-5 lg:ml-[30px]" key={i}>
                                        <div className='h-48 lg:h-[218px] w-full md:w-full lg:w-[245px] flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden rounded-md m-0 md:m-0 lg:m-[10px] relative'>
                                            <div className='absolute right-[15px] top-[15px] cursor-pointer'>
                                                { }
                                                <img className="h-full w-full z-9" src={a.bookmarked ? SlectedBookmark : BookMark} alt="" onClick={() => AddToBookMark(a)} />
                                            </div>
                                            <div className='w-full h-full cursor-pointer' onClick={() => openProductInNewTab(a.id)}>
                                                {a.image && /\.(mp4|webm|ogg)$/i.test(a.image) ? (
                                                    <video className="h-full w-full object-cover" controls>
                                                        <source src={a.image} type="video/mp4" />
                                                        <source src={a.image} type="video/webm" />
                                                        <source src={a.image} type="video/ogg" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : (
                                                    <img
                                                        className="h-full w-full object-cover"
                                                        src={a.image.length ? a.image : defaultimage}
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className='w-full h-auto md:h-[218px] lg:h-[218px] cursor-pointer' onClick={() => openProductInNewTab(a.id)}>
                                            <div className="w-full h-38 lg:h-[218px] rounded-b lg:rounded-b-none lg:rounded-r px-4 flex flex-col justify-between leading-normal mt-[10px]">
                                                <div className="flex flex-col justify-between leading-normal h-full mb-5 md:mb-0 lg:mb-0">
                                                    <div className='h-[80%]'>
                                                        <div className='flex'>
                                                            <div className='w-full'>
                                                                <div className="text-xl font-medium text-left text-[#2d3136] flex justify-between">
                                                                    <p>{a.full_name}</p>
                                                                    {a.status === 'Sold' && <div className='h-fit w-fit bg-[#FEE0E0] rounded-md'>
                                                                        <p className="text-xs font-medium Def-font text-left uppercase text-[#e95502] flex items-center justify-center m-1 rounded-sm mx-2">
                                                                            {a.status}
                                                                        </p>
                                                                    </div>}
                                                                </div>
                                                                <div className='flex w-full'>
                                                                    {/* <p className="text-lg font-medium text-left text-[#737c87] w-1/2">{`${a.price?.toLocaleString(currencies?.format, { style: 'currency', currency: currencies?.key })}`}</p> */}
                                                                    <p className="text-lg font-medium text-left text-[#737c87] w-1/2">{a?.price ? formatter.format(a.price) : `$ 0`}</p>
                                                                    <div className='flex items-center justify-end w-1/2'>
                                                                        <div className="h-[21px] flex mb-[8px] mt-[4px]">
                                                                            {a.rating && <img className="pr-2" src={star} alt="" />}
                                                                            <p className="text-sm font-medium text-left text-[#737c87] pr-2">{a?.rating.average_rating ? a.rating.average_rating : '0'}</p>
                                                                            <p className="text-sm text-left text-[#737c87]">({a.rating.num_reviews} {prop?.data?.CardData?.LargeCard?.review})</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="w-[80%] text-base text-left text-[#737c87] line-clamp-2 md:line-clamp-2 lg:line-clamp-3 hidden sm:block">
                                                            {a.description && <span className="w-[70%] text-base text-left text-[#737c87]">{a.description}</span>}
                                                        </p>
                                                    </div>
                                                    <div className='w-full flex justify-center md:justify-start lg:justify-start'>
                                                        <div className=' hidden md:block lg:block'>
                                                            {/* <NavLink to={a.id ? `/product-detail/${a.id}` : '/404'}> */}
                                                            <div className="w-[157px] h-[42px] rounded-[10px] bg-[#2d3136] mt-[10px] flex items-center justify-center cursor-pointer" onClick={() => openProductInNewTab(a.id)}>
                                                                <p className="text-sm font-medium text-center uppercase text-[#ffba40]">{prop?.data?.CardData?.LargeCard?.button}</p>
                                                            </div>
                                                            {/* </NavLink> */}
                                                        </div>
                                                        <NavLink to={a.id ? `/product-detail/${a.id}` : '/404'}>
                                                            <div className="w-[157px] h-[42px] rounded-[10px] BGForButtons mt-[10px] flex items-center justify-center block md:hidden lg:hidden">
                                                                <p className="text-sm font-medium text-center uppercase text-[#2d3136]">{prop?.data?.CardData?.LargeCard?.button}</p>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {(CarsRawData.length > 18) && <p className="text-sm font-medium text-center text-[#ffba40] cursor-pointer" onClick={() => onsubmit()}>Load More ...</p>}
                        </div>
                    </div>
                    <div className='pt-[20px] w-full'>
                        {propData.subbanner}
                    </div>
                </div >}
                {Tabdata === 'Sell' && <div className='px-[5%]'>
                    <div className='w-full flex justify-end'>
                        <div className="w-[150px] h-5 flex items-center cursor-pointer" onClick={() => SwitchNewpost()}>
                            <img className="h-[20px] w-[20px] mr-[10px]" src={AddFile} alt="" />
                            <p className="text-base font-medium text-right text-[#ffba40]">
                                Add new post
                            </p>
                        </div>
                    </div>
                    <Cards data={userdata} AllData={prop.data.CardData.Cards} />
                </div>}
            </div>
        </>
    );
}

export default Cservices;
