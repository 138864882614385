import React, { useState, useEffect } from 'react';
import LoginPageImg from '../../../assets/LoginPageImg.svg'
import LoginAlternative from './LoginAlternative';
import LoginWithNumber from './LoginWithNumber';
import { useDispatch, useSelector } from 'react-redux';
// import { ErrorMessage } from '../../../views/Configurations/language'
import { StoreLanguage } from '../../Store/index'
import { SetProfileStatus, SetUserData, setProps } from '../../Store/index'
import { SetUserLogin, SetUserRegister, Setpassword, SetUserOtp, SetUserverifyotp, GetUserprofile, SetSocialUser } from '../../../server/index'
import './LoginStyle.css'

const Login = (prop) => {
    const dispatch = useDispatch()
    const [Authorization, SetAuthorization] = useState('')
    const [CarsData, SetcarsData] = useState(prop)
    const [UserinputData, SetUserinputData] = useState({})
    const profiles = useSelector(state => state.storeContent.UserData);
    const [UserMailVerfy, SetUserMailVerfy] = useState(false)
    const profileverification = async (profiles) => {
        if (profiles.sub && profiles.name && profiles.email) {
            try {
                if (profiles.provider === 'google') {
                    const { data } = await SetSocialUser({ social_provider: profiles.provider, social_token: profiles.sub, email: profiles.email, first_name: profiles.name });
                    if (data?.phone_number_verified) {
                        SetUserMailVerfy(true)
                        Close()
                        fetchData(data.auth_token);
                    } else if (data.message) {
                        setLogin('LoginWithNumber')
                    } else {
                        setLogin('VerifyOtp')
                    }
                }
            } catch (error) {
                console.error(error);
                setLogin('LoginWithNumber')
            }
        }
        if (profiles.userID && `${profiles.first_name} ${profiles.last_name}` && profiles.email) {
            try {
                if (profiles.provider === 'facebook') {
                    const { data } = await SetSocialUser({ social_provider: profiles.provider, social_token: profiles.userID, email: profiles.email, first_name: `${profiles.first_name} ${profiles.last_name}` });
                    if (data?.phone_number_verified) {
                        SetUserMailVerfy(true)
                        Close()
                        fetchData(data.auth_token);
                    } else if (data.message) {
                        setLogin('LoginWithNumber')
                    } else {
                        setLogin('VerifyOtp')
                    }
                }
            } catch (error) {
                console.error(error);
                setLogin('LoginWithNumber')
            }
        }
    }
    const datafromalternavive = (prop) => {
        profileverification(prop)
    }
    const Backtoperiouspage = (prop) => {
        setLogin(prop)
    }
    const fetchData = async (Authorization) => {
        try {
            if (Authorization) {
                let { data } = await GetUserprofile({
                    headers: {
                        Authorization: `Token ${Authorization}`,
                    }
                })
                let tempdata = data
                if (tempdata?.phone_number_verified) {
                    Close()
                }
                if (tempdata?.email_verified) {
                    Close()
                }
                dispatch(SetUserData({ Authorization, ...profiles, ...tempdata }))
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData(Authorization);
    }, [Authorization]);
    const divStyle = {
        backgroundImage: `url(${LoginPageImg})`
    };
    const UserData = (prop) => {
        continuewithdevice(prop)
        SetUserinputData(prop)
    }
    const [LoginData, SetLoginData] = useState({
        inetialpager: true,
        LoginWithNumber: false,
        ConformNumber: false,
        resetPass: false,
        VerifyOtp: false
    })
    const setLogin = (field) => {
        let { [field]: selectedField, ...rest } = LoginData;
        selectedField = true;
        SetLoginData({ [field]: selectedField, ...Object.fromEntries(Object.keys(rest).map(key => [key, false])) });
    };
    const continuewithdevice = async (Data) => {
        if (UserMailVerfy) {
            return
        }
        const header = {
            headers: {
                Authorization: `Token ${Authorization}`,
                'Content-Type': 'application/json'
            }
        }
        if (Data.Status === 'ConformNumber') {
            try {
                if (profiles.provider === 'google') {
                    const { data } = await SetSocialUser({ ...Data, social_provider: profiles.provider, social_token: profiles.sub, email: profiles.email, first_name: profiles.name });
                    if (!data.non_field_errors) {
                        setLogin('ConformNumber');
                        SetAuthorization(data.auth_token);
                    }
                } else if (profiles.provider === 'facebook') {
                    const { data } = await SetSocialUser({ ...Data, social_provider: profiles.provider, social_token: profiles.userID, email: profiles.email, first_name: `${profiles.first_name} ${profiles.last_name}` });
                    if (!data.non_field_errors) {
                        setLogin('ConformNumber');
                        SetAuthorization(data.auth_token);
                    }
                } else {
                    if (Data.userstatus) {
                        const { data } = await SetUserLogin(Data)
                        fetchData(data.auth_token)
                        dispatch(setProps([prop.data.PopUpDataSuccessMessage.LoginWithNumber]))
                    } else {
                        const { data } = await SetUserRegister(Data);
                        if (!data.non_field_errors) {
                            setLogin('ConformNumber');
                            SetAuthorization(data.auth_token);
                        }
                    }
                }
            } catch (error) {
                if (error?.response?.status === 500) {
                    dispatch(StoreLanguage.setProps([{
                        Title: '500 server error',
                        SubTitle: '500 server error',
                        Styling: {
                            Icon: 'Error',
                        }
                    }]));
                } else {
                    let temp = []
                    for (let er in error?.response?.data) {
                        temp.push({
                            Title: er,
                            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                            Styling: {
                                Icon: 'Error',
                            }
                        })
                    }
                    dispatch(StoreLanguage.setProps(temp))
                }
            }
        } else if (Data.Status === 'OtpScreen') {
            setLogin('VerifyOtp')
            try {
                await SetUserOtp(Data)
            } catch (error) {
                if (error?.response?.status === 500) {
                    dispatch(StoreLanguage.setProps([{
                        Title: '500 server error',
                        SubTitle: '500 server error',
                        Styling: {
                            Icon: 'Error',
                        }
                    }]));
                } else {
                    let temp = []
                    for (let er in error?.response?.data) {
                        temp.push({
                            Title: er,
                            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                            Styling: {
                                Icon: 'Error',
                            }
                        })
                    }
                    dispatch(StoreLanguage.setProps(temp))
                }
            }
        } else if (Data.Status === 'VerifyOTP') {
            try {
                await SetUserverifyotp(header, Data)
                setLogin('resetPass')
            } catch (error) {
                if (error?.response?.status === 500) {
                    dispatch(StoreLanguage.setProps([{
                        Title: '500 server error',
                        SubTitle: '500 server error',
                        Styling: {
                            Icon: 'Error',
                        }
                    }]));
                } else {
                    let temp = []
                    for (let er in error?.response?.data) {
                        temp.push({
                            Title: er,
                            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                            Styling: {
                                Icon: 'Error',
                            }
                        })
                    }
                    dispatch(StoreLanguage.setProps(temp))
                }
                setLogin('ConformNumber')
            }
        } else if (Data.Status === 'forgetpassword') {
            try {
                await Setpassword(header, { ...UserinputData, confirm_password: Data.confirm_password, password: Data.password })
                // if (data.new_password) {
                const { data } = await SetUserLogin(Data)
                fetchData(data.auth_token)
                dispatch(setProps([prop.data.PopUpDataSuccessMessage.LoginWithNumber]))
                Close()
                dispatch(SetProfileStatus())
                checkfunction()
                // }
            } catch (error) {
                if (error?.response?.status === 500) {
                    dispatch(StoreLanguage.setProps([{
                        Title: '500 server error',
                        SubTitle: '500 server error',
                        Styling: {
                            Icon: 'Error',
                        }
                    }]));
                } else {
                    let temp = []
                    for (let er in error?.response?.data) {
                        temp.push({
                            Title: er,
                            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                            Styling: {
                                Icon: 'Error',
                            }
                        })
                    }
                    dispatch(StoreLanguage.setProps(temp))
                }
            }
            // setLogin('resetPass')
        } else if (Data.Status === 'ForGetPassword') {
            try {

            } catch (error) {

            }
            setLogin('ConformNumber')
        }
    }
    const DataToToggle = (data) => {
        SetLoginData(data)
    }
    const Close = () => {
        SetLoginData({
            inetialpager: false,
            LoginWithNumber: false,
            ConformNumber: false,
            resetPass: false,
            VerifyOtp: false
        })
        prop.UnableSignin(false)
    }
    const checkfunction = async () => {
        try {
            let { data } = await GetUserprofile({
                headers: {
                    Authorization: `Token ${Authorization}`,
                }
            })
            localStorage.setItem(`UserData`, JSON.stringify({ ...data, Authorization }));
            dispatch(SetUserData({ ...data, Authorization }))
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    const resetData = () => {
        SetUserinputData({
            "country": "Hong Kong",
            "phone_number": "1",
            "password": "",
            "country_code": "+852",
            "confirm_password": "",
            "userstatus": false,
            "isEmailOrNumber": true
        })
    }
    return (
        ((LoginData.inetialpager || LoginData.LoginWithNumber || LoginData.ConformNumber || LoginData.resetPass || LoginData.VerifyOtp) && <div className="w-full h-full bg_blur fixed top-0 z-[12]">
            <div className="w-[30px] h-[30px] absolute right-[5%] top-[5%] z-8 rounded-full flex justify-center items-center text-xl cursor-pointer Def-font bg-[#62656D] text-white z-10" onClick={() => Close()}>x</div>
            <div className='w-full md:w-[35%] lg:w-[45%] h-full absolute right-0 Def-font'>
                <div className='bg-[#3B3F49] w-full h-full'>
                    <div style={divStyle} className='h-[26%] md:h-[28%] lg:h-[30%] w-full bg-no-repeat bg-cover'></div>
                    <div className='flex justify-center bg-[#3B3F49] relative'>
                        {LoginData.inetialpager && <LoginAlternative resetData={resetData} data={CarsData.data} LoginData={LoginData} rawdata={CarsData.data} DataToToggle={DataToToggle} datafromalternavive={datafromalternavive} UnableSignin={CarsData.UnableSignin} statusdata={'inetialpager'} />}
                        {LoginData.LoginWithNumber && <LoginWithNumber resetData={resetData} data={CarsData.data.dataforlogn.LoginWithNumber} rawdata={CarsData.data} DataToToggle={DataToToggle} UserData={UserData} statusdata={'LoginWithNumber'} routeDirection={'inetialpager'} Backtoperiouspage={Backtoperiouspage} />}
                        {LoginData.ConformNumber && <LoginWithNumber resetData={resetData} data={CarsData.data.dataforlogn.ConformNumber} rawdata={CarsData.data} DataToToggle={DataToToggle} UserinputData={UserinputData} UserData={UserData} statusdata={'inetialpager'} routeDirection={'LoginWithNumber'} Backtoperiouspage={Backtoperiouspage} />}
                        {LoginData.resetPass && <LoginWithNumber resetData={resetData} data={CarsData.data.dataforlogn.resetPass} rawdata={CarsData.data} DataToToggle={DataToToggle} UserinputData={UserinputData} UserData={UserData} statusdata={'resetPass'} routeDirection={'ConformNumber'} Backtoperiouspage={Backtoperiouspage} />}
                        {LoginData.VerifyOtp && <LoginWithNumber resetData={resetData} data={CarsData.data.dataforlogn.VerifyOtp} rawdata={CarsData.data} DataToToggle={DataToToggle} UserinputData={UserinputData} UserData={UserData} statusdata={'VerifyOtp'} routeDirection={'ConformNumber'} Backtoperiouspage={Backtoperiouspage} />}
                    </div>
                </div>
            </div>
        </div>)
    );
};

export default Login;