import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import './PriceSlide.css';

const PriceRangeSlider = (Incoming) => {
    const [ParentData, setParentData] = useState(Incoming.Data)
    const min = Incoming.Data.MoreOption[0]
    const max = Incoming.Data.MoreOption[1]
    const [minPrice, setMinPrice] = useState(Incoming.Data.MoreOption[0]);
    const [maxPrice, setMaxPrice] = useState(Incoming.Data.MoreOption[1]);
    const currencies = JSON.parse(localStorage.getItem(`Cur`))
    const handleOnChange = (values) => {
        setMinPrice(values[0]);
        setMaxPrice(values[1]);
        Incoming.MInAndMax(values)
    };

    return (
        <div>
            <h2 className=''>Price Range Slider</h2>
            <div className='flex justify-between mx-5'>
                <p>Min Price: {currencies.symbol}{minPrice}</p>
                <p>Max Price: {currencies.symbol}{maxPrice}</p>
            </div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={[min, max]}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => (
                    <div {...props}>
                    </div>
                )}
                onAfterChange={handleOnChange}
                pearling
                minDistance={1000}
                max={max}
                min={min}
                // onChange={handleOnChange}
            />
        </div>
    );
};

export default PriceRangeSlider;
