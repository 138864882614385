import React, { useState, useEffect } from 'react';
import star from '../../assets/star.svg'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SlectedBookmark from '../../assets/SelectedBookMark.svg'
import defaultimage from '../../assets/DefaultImage.svg'
import BookMark from '../../assets/BookMark.svg'
const Body = (prop) => {
    const [propData, SetpropData] = useState([])
    const [propNoData, SetpropNoData] = useState(prop.nodata)
    const currencies = JSON.parse(localStorage.getItem(`Cur`))
    const profiles = useSelector(state => state.storeContent.UserData);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    useEffect(() => {
        if (prop.data && prop.data.results) {
            SetpropData(prop.data.results)
        } else if (prop.data.length) {
            SetpropData(prop.data)
        }
        SetpropNoData(prop.nodata)
    }, [prop]);
    const openProductInNewTab = (props) =>{
        window.open(`${window.location.origin}/product-detail/${props}`, '_blank');
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <>
            <div className={`${propData?.length > 0 ? 'py-[20px] md:py-[30px] lg:py-[50px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 justify-between' : 'pt-10 md:pt-17 lg:pt-20 flex justify-center items-center'}`}>
                {
                    propData?.length > 0 && propData.map(a => (
                        <div className="min-w-[350px] max-w-[530px] flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-[20px] h-auto md:h-[200px] lg:h-[220px] md:mb-[30px] lg:mb-[50px]"  onClick={() => openProductInNewTab(a.car_vehicle?.id ? a.car_vehicle?.id : a?.id)}>
                            <div className='object-cover rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg pl-0 md:pl-[10px] lg:pl-[10px] relative cursor-pointer' style={{ height: '90%', width: '100%' }}>
                                {a?.car_vehicle?.bookmarked && <div className='absolute right-[15px] top-[15px] cursor-pointer z-10' onClick={() => prop.AddToBookMark(profiles, a)}>
                                    { }
                                    <img className="h-full w-full" src={a.car_vehicle.bookmarked ? SlectedBookmark : BookMark} alt="" />
                                </div>}
                                {/* <img className="w-full h-full object-cover rounded-md" src={ } alt="" /> */}
                                {/\.(mp4|webm|ogg)$/i.test(a.car_vehicle?.image?.length ? a.car_vehicle.image : a.image ) ? (
                                    <video className="h-full w-full object-cover" controls>
                                        <source src={a.car_vehicle?.image?.length ? a.car_vehicle.image : a.image} type="video/mp4" />
                                        <source src={a.car_vehicle?.image?.length ? a.car_vehicle.image : a.image} type="video/webm" />
                                        <source src={a.car_vehicle?.image?.length ? a.car_vehicle.image : a.image} type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        className="h-full w-full object-cover"
                                        src={a.car_vehicle ? a.car_vehicle.image.length ? a.car_vehicle.image : defaultimage : a.image.length ? a.image : defaultimage}
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="cursor-pointer w-full">
                                <div className="flex flex-col justify-between p-4 leading-normal w-full h-auto md:h-[200px] lg:h-[220px]">
                                    <div>
                                        <div className='flex'>
                                            <div className='w-full'>
                                                <p className="text-xl font-medium text-left Def-font text-[#2d3136]">{a.car_vehicle?.full_name ? a.car_vehicle?.full_name : a?.full_name}</p>
                                                <div className='flex w-full'>
                                                    <p className="text-md font-medium text-left Def-font text-[#737c87]  w-full">{a.car_vehicle ? `${a.car_vehicle.price?.toLocaleString(currencies?.format, { style: 'currency', currency: currencies.key })}` : `${a.price?.toLocaleString(currencies?.format, { style: 'currency', currency: currencies.key })}`}</p>
                                                    {/* <p className="text-md font-medium text-left Def-font text-[#737c87]  w-full">{a.car_vehicle ? formatter.format(a.car_vehicle.price) : formatter.format(a.price)}</p> */}
                                                    {a.status && <div className='w-full flex items-center justify-end'>
                                                        <div className="w-[100px] h-[22px]">
                                                            <div className="w-[100px] h-[22px] rounded-[5px] bg-[#fee3e0] flex items-center justify-center">
                                                                <p className="text-xs font-medium Def-font text-left uppercase text-[#e95502] flex items-center justify-center">
                                                                    {a.status}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="h-[21px] flex">
                                            {<img className="pr-2" src={star} alt="" />}
                                            <p className="text-sm font-medium text-left Def-font text-[#737c87] pr-2">{a.rating?.average_rating ? a.rating?.average_rating : '0'}</p>
                                            <p className="text-sm text-left text-[#737c87] Def-font">({a.rating?.num_reviews ? a.rating?.num_reviews : '0'} {prop?.AllData?.review})</p>
                                        </div>
                                    </div>
                                    {(a.car_vehicle || a.car_vehicle?.description) && <p className="w-[300px] text-base text-left Def-font text-[#737c87]">
                                        <span className="w-[300px] text-base text-left text-[#737c87] Def-font">{a.car_vehicle.description?.substring(0, 72)}{a.car_vehicle.description?.length > 72 && '...'} </span>
                                        {/* {a.car_vehicle.description?.length > 72 && <span className="Def-font w-[300px] text-base font-medium text-left text-[#737c87]">more</span>} */}
                                    </p>}
                                    {(!a.car_vehicle || a?.description) && <p className="w-[300px] Def-font text-base text-left text-[#737c87]">
                                        <span className="w-[300px] text-base text-left Def-font text-[#737c87]">{a.description?.substring(0, 72)}{a.description?.length > 72 && '...'} </span>
                                        {/* {a.description?.length > 72 && <span className="w-[300px] Def-font text-base font-medium text-left text-[#737c87]">more</span>} */}
                                    </p>}
                                    <div className='hidden md:block lg:block'>
                                        {/* <NavLink to={a.id ? `/product-detail/${a.car_vehicle?.id ? a.car_vehicle?.id : a?.id}` : '/404'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}> */}
                                        <div className="cursor-pointer w-[157px] h-[42px] rounded-[10px] bg-[#2d3136] mt-[2px] flex items-center justify-center">
                                            <p className="text-sm font-medium text-center uppercase Def-font text-[#ffba40]">
                                                {prop?.AllData?.button}
                                            </p>
                                        </div>

                                        {/* </NavLink> */}
                                    </div>
                                    {/* <NavLink to={a.id ? `/product-detail/${a.id}` : '/404'}> */}
                                    <div className="w-[157px] h-[42px] rounded-[10px] BGForButtons mt-[10px] flex items-center justify-center block md:hidden lg:hidden cursor-pointer">
                                        <p className="text-sm font-medium text-center uppercase Def-font text-[#2d3136]">
                                            {prop?.AllData?.button}
                                        </p>
                                    </div>
                                    {/* </NavLink> */}
                                </div>
                            </div>
                        </div>
                    ))

                }
                <div>
                    <NavLink to={'/product-detail'}>
                        {!propData?.length > 0 && < div className="w-[320px] md:w-[720px] lg:w-[920px] h-[300px] md:h-[359px] lg:h-[409px]">
                            <div
                                className="w-[320px] md:w-[720px] lg:w-[920px] h-[300px] md:h-[359px] lg:h-[409px] rounded-[30px] bg-[#ffffff26] border border-white flex justify-center items-center"
                                style={{ boxShadow: "0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                            >
                                <div className="w-[300px] md:w-[550px] lg:w-[619px] h-[160px] md:h-[180px] lg:h-[209px] flex flex-col justify-center items-center">
                                    <p className="text-[24px] md:text-[28px] lg:text-[34px] font-medium text-center text-white Def-font">
                                        {propNoData?.title ? propNoData?.title : 'No data found'}
                                    </p>
                                    <p className="w-[300px] md:w-[400px] lg:w-[458px] text-xl text-center text-white Def-font">
                                        {propNoData?.subtitle ? propNoData?.subtitle : !profiles?.id ? 'Please Login to see your data' : 'No History'}
                                    </p>
                                    <div className="w-[231px] h-[58px] flex justify-center items-center">

                                        <div className="h-12 mt-[10px] lg:mt-[30px]">
                                            <p className="text-sm lg:text-base font-medium text-left uppercase text-[#333] Choose_Best h-[27px] md:h-[34] lg:h-[48px] w-[160px] md:w-[200px] lg:w-[227px] rounded-[5px] lg:rounded-[10px] flex justify-center items-center Def-font">{propNoData?.button ? propNoData?.button : 'In Pogress'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </NavLink>
                </div>

            </div >
        </>
    );
};

export default Body;
