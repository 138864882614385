import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './PriceSlide.css';
import { GetMoteHistory } from '../../server/index'
import bgimage from '../../assets/carforHistorycar.svg'
import { StoreLanguage } from '../Store/index'
import EmptyScreen from '../Helper/EmptyScreen';

const PriceRangeSlider = () => {

    const profiles = useSelector(state => state.storeContent.UserData);
    const notification = useSelector(state => state.storeContent.popup);
    const dispatch = useDispatch()

    const [Mot, setMot] = useState([])
    useEffect(() => {
        if (profiles?.Authorization) {
            GettingHistory()
        }
    }, [profiles, notification]);
    const GettingHistory = async () => {
        try {
            const { data } = await GetMoteHistory({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            })
            setMot(data.results)
        } catch (error) {
            // let temp = []
            // for (let er in error?.response?.data) {
            //     temp.push({
            //         Title: er,
            //         SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
            //         Styling: {
            //             Icon: 'Error',
            //         }
            //     })
            // }
            // dispatch(StoreLanguage.setProps(temp))
        }
    }
    return (
        <>
            <div className='w-full px-[5%] py-[2%] Def-font'>
                <div className='h-[250px] md:h-[320px] lg:h-[360px] relative'>
                    <div style={{ backgroundImage: `url(${bgimage})`, borderRadius: '24px' }} className="w-full h-full bg-center bg-no-repeat bg-cover"></div>
                    <div className='absolute top-0 left-[15px] md:left-[40px] lg:left-[80px] right-0 bottom-0 flex items-center justify-start'>
                        <p className="text-left w-[200px] md:w-[270px] lg:w-[430px]">
                            <span className="text-[1rem] md:text-[1.5rem] lg:text-5xl text-left text-white">Ministry of Transport
                                (MOT) history</span>
                        </p>
                    </div>
                </div>
                <div className='py-[20px] md:py-[30px] lg:py-[50px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 justify-between gap-4'>
                    {Mot?.length > 0 && Mot.map(a => <div
                        className="w-full md:w-[405px] lg:w-[460px] h-[251px] rounded-[10px] bg-white p-[3%] mb-2 md:mb-5 lg:mb-6"
                    >
                        <div className="w-full md:w-[405px] lg:w-[425px] h-[22px]">
                            <div
                                className="w-full md:w-[405px] lg:w-[425px] h-[25px] rounded-[5px] bg-[#e0fafe] flex justify-between items-center px-5"
                            >
                                <p className="text-xs font-medium text-left text-[#737c87]">
                                    {a?.date && new Date(a.date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                    {!a.date && 'No date'}
                                </p>
                                <p className="text-xs font-medium text-left text-[#737c87]">
                                    {a.time}
                                </p>
                                <p className="text-xs font-medium text-right text-[#737c87]">
                                    {a.place}
                                </p>
                            </div>
                        </div>
                        <div className='py-2'>
                            <div className='w-full flex justify-between items-center'>
                                <p className="text-xl font-medium text-left text-[#2d3136]">
                                    {a.model}
                                </p>
                                <div className="w-24 h-[22px]">
                                    <div
                                        className={a.status === 'Incomplete' ? "w-24 h-[22px] rounded-[5px] bg-[#fee0e0] flex justify-center items-center" : 'w-[92px] h-[22px] rounded-[5px] bg-[#e8fee0] flex justify-center items-center'}
                                    >
                                        <p
                                            className={a.status === 'Incomplete' ? "text-[11px] font-medium text-right uppercase text-[#d80505]" : 'w-[92px] h-[22px] rounded-[5px] bg-[#e8fee0]'}
                                        >
                                            {a.status === 'Incomplete' ? 'Incomplete' : 'Completed'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className="w-full md:w-[405px] lg:w-[425px] text-sm text-left text-[#737c87]">
                                <span className="w-full md:w-[405px] lg:w-[425px] text-sm text-left text-[#737c87]"
                                >{a.status_reason?.slice(0, 70)}</span>
                                {a?.status_reason?.length > 70 && <span className="w-[535px] text-sm font-medium text-left text-[#737c87]">... more</span>}
                            </p>
                        </div>
                        <div className='grid grid-cols-2 gap-3'>
                            <div className="">
                                <p className="text-sm text-left text-[#737c87]">Year</p>
                                <p className="text-lg font-medium text-left text-[#737c87]">
                                    {a.year}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-sm text-left text-[#737c87]">Make</p>
                                <p className="text-lg font-medium text-left text-[#737c87]">
                                    {a.make}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-sm text-left text-[#737c87]">
                                    Engine Displacement
                                </p>
                                <p className="text-lg font-medium text-left text-[#737c87]">
                                    {a.engine_displacement}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-sm text-left text-[#737c87]">Uploaded VRD</p>
                                <span className="text-lg font-medium text-left text-[#737c87] flex items-center">
                                    <div className='w-[15px] mr-2'>
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-4 h-4 mr-2"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M9.61755 15.6903C7.63699 17.4441 4.45249 17.4371 2.48297 15.6658C0.51401 13.8955 0.506251 11.031 2.45647 9.24974L2.45483 9.24871L9.61868 2.80614L10.5146 2.00073C11.9984 0.666423 14.4034 0.666423 15.8872 2.00073C17.3709 3.33504 17.3709 5.49824 15.8872 6.83306L7.99029 13.8611L7.98752 13.8591C7.01433 14.703 5.46321 14.697 4.50163 13.8366C3.54005 12.9757 3.53399 11.5879 4.47678 10.718L4.474 10.715L5.35333 9.92912L9.75001 5.99618L10.6288 6.78307L5.35333 11.5019C4.86755 11.9358 4.86755 12.6402 5.35333 13.0747C5.8391 13.5091 6.62677 13.5091 7.11198 13.0747L14.9917 6.02663L14.9895 6.02514L15.0193 6.00212C16.0086 5.11273 16.0086 3.66993 15.0193 2.7806C14.0301 1.89127 12.4272 1.89122 11.438 2.7806L11.4115 2.80762L11.4098 2.8066L10.5145 3.61149L3.35018 10.0541C1.86643 11.3884 1.86643 13.5516 3.35018 14.8859C4.83393 16.2202 7.23959 16.2202 8.7222 14.8859L14.9907 9.24871L15.8861 8.44382L16.7814 9.24871L15.8861 10.0541L9.61868 15.6913L9.61755 15.6903Z"
                                                fill="#737C87"
                                                stroke="#737C87"
                                                stroke-width="0.5"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className=''>
                                        {a.upload_vrd[0].substring(a.upload_vrd[0].lastIndexOf('/') + 1).slice(0, 12) + (a.upload_vrd[0].substring(a.upload_vrd[0].lastIndexOf('/') + 1).length > 12 ? '...' : '')}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>)}
                </div >
                {Mot?.length == 0 &&
                    <div className='w-full flex justify-center items-center'>
                        <EmptyScreen />
                    </div>
                }
            </div>
        </>
    );
};

export default PriceRangeSlider;
