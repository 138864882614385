import axios from 'axios'

const BaseURL = 'https://101mile.com/backend_service/api/v1'

export function SetUserLogin(params) {
    return axios.post(`${BaseURL}/user/login/`, params)
}
export function SetGLogin(params) {
    return axios.get(`${BaseURL}/user/code_verify/?code=${params?.code}&uuid=${params?.uuid}`)
}
export function SetUserRegister(params) {
    return axios.post(`${BaseURL}/user/register/`, params)
}

export function SetSocialUser(params) {
    return axios.post(`${BaseURL}/user/social-login-api/`, params)
}

export function SetUserOtp(params) {
    return axios.post(`${BaseURL}/user/generate_otp/`, { ...params })
}

export function SetUserverifyotp(header, body) {
    return axios.post(`${BaseURL}/user/verify_otp/`, body, header)
}

export function GetUserprofile(params) {
    return axios.get(`${BaseURL}/user/profile/`, { ...params })
}

export function SetUserprofileupdate(header, body) {
    return axios.patch(`${BaseURL}/user/profile-update/`, body, header)
}

export function SetLogOut(header) {
    return axios.post(`${BaseURL}/user/logout/`, null, header)
}

export function Setcheckuserstatus(body) {
    return axios.post(`${BaseURL}/user/check_user_exists/`, { ...body })
}


export function Setpassword(header, body) {
    return axios.post(`${BaseURL}/user/set_password/`, body, header)
}

export function GetAllCars(header, URl = '?for_sale=true/') {
    return axios.get(`${BaseURL}/vehicle/router/car_vehicle/${URl}`, header)
}

export function GetCar(header, body) {
    return axios.get(`${BaseURL}/vehicle/router/car_vehicle/${body}/`, header)
}

export function GetSimilarCar(header, price) {
    return axios.get(`${BaseURL}/vehicle/router/car_vehicle/?similar_price=${price}&limit=6`, header)
}

export function GetUserAllCars(header, id) {
    return axios.get(`${BaseURL}/vehicle/user-cars/`, header)
}

export function GetCompareCars(header, body) {
    return axios.get(`${BaseURL}/vehicle/compare-cars/?${body}`, header)
}

export function StoreCompareCars(header, body) {
    return axios.get(`${BaseURL}/vehicle/add_compare/?vehicle_id=${body}`, header)
}

export function GetAllCompareCars(header) {
    return axios.get(`${BaseURL}/vehicle/get_all_compare/`, header)
}

export function RemoveCompareCars(header, body) {
    return axios.get(`${BaseURL}/vehicle/remove_compare/?vehicle_id=${body}`, header)
}

export function SetBookmarks(header) {
    return axios.get(`${BaseURL}/vehicle/bookmarks/`, header)
}

export function CreateBookmarks(header, body) {
    return axios.post(`${BaseURL}/vehicle/bookmarks/create/`, body, header)
}

export function GetBanners() {
    return axios.get(`${BaseURL}/vehicle/dashboard-banner/`)
}

export function createroadassistrequest(header, body) {
    return axios.post(`${BaseURL}/services/roadassistrequest/`, body, header)
}

export function get_in_touch(header, body) {
    return axios.post(`${BaseURL}/services/get_in_touch/`, body, header)
}

export function vehicleHistory(header, body) {
    return axios.get(`${BaseURL}/services/vehicle_history/?user=${body}`, header)
}
export function GetSearchCar(body) {
    return axios.get(`${BaseURL}/vehicle/router/car_vehicle/?search=${body}`)
}
export function UploadCarData(header, body) {
    return axios.post(`${BaseURL}/vehicle/router/car_vehicle/`, body, header)
}

export function UpdateCarData(header, body, id) {
    return axios.patch(`${BaseURL}/vehicle/router/car_vehicle/${id}/`, body, header)
}

export function GetbackRequest() {
    return axios.get(`${BaseURL}/services/get_in_touch/`)
}

export function RequesrRating(header, body) {
    return axios.post(`${BaseURL}/vehicle/router/vehicle_rating/`, body, header)
}

export function ReviewOfVehicle(header) {
    return axios.get(`${BaseURL}/vehicle/router/vehicle_rating/`, header)
}

export function SendMOT(header, body) {
    return axios.post(`${BaseURL}/vehicle/router/mot/`, body, header)
}

export function Schedule(header, body, id) {
    return axios.patch(`${BaseURL}/vehicle/router/mot/${id}/`, body, header)
}

export function SideFilter(header) {
    return axios.get(`${BaseURL}/vehicle/vehicle_car_static_data/`, header)
}

export function ServiceImages() {
    return axios.get(`${BaseURL}/services/service/`)
}

export function DeleatUser(header) {
    return axios.delete(`${BaseURL}/user/disable-account/`, header)
}

export function Notification(header) {
    return axios.get(`${BaseURL}/user/router/notification/`, header)
}

export function UserCars(header, id, exclude) {
    return axios.get(`${BaseURL}/vehicle/user-cars/${id}/?exclude=${exclude}`, header)
}

export function ChnageStatus(header, body) {
    return axios.post(`${BaseURL}/services/update_vehicle_status/`, body, header)
}

export function GetMoteHistory(header) {
    return axios.get(`${BaseURL}/vehicle/router/mot/`, header)
}

export function usersList(header) {
    return axios.get(`${BaseURL}/user/users/?remove_pagination=true`, header)
}