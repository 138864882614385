import React, { useState } from 'react';
import Rinput from '../../Helper/RInput'
import './RateReviewStyle.css'
import { SetMOT, SetScheduleMOT, StoreLanguage } from '../../Store/index';
import './RateReviewStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { Schedule } from '../../../server/index'
// import { ErrorMessage } from '../../../views/Configurations/language'
const Profile = (props) => {
    const dispatch = useDispatch();
    const profiles = useSelector(state => state.storeContent.UserData);
    const [RawData, SetRawData] = useState(props.data);
    const [MOTData, SetMOTData] = useState([]);
    const MOTID = JSON.parse(localStorage.getItem(`MOTID`))
    const Rinputdata = (prop) => {
        const existingIndex = MOTData.findIndex((item) => item.Name === prop.Name);
        if (existingIndex !== -1) {
            const updatedMOTData = [...MOTData];
            updatedMOTData[existingIndex] = { ...updatedMOTData[existingIndex], ...prop };
            SetMOTData(updatedMOTData);
        } else {
            SetMOTData([...MOTData, { ...prop }]);
        }
    };
    const Reduce = (converted) => {
        return converted.reduce((acc, obj) => {
            Object.keys(obj).forEach(key => {
                acc[key] = obj[key];
            });
            return acc;
        }, {});
    }
    const SendMOTFun = async () => {
        let MOT = Reduce(MOTData.map(a => ({ [a.Name]: a.UserEnterData })))
        const formattedDate = new Date(MOT?.date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        MOT = { ...MOT, date: formattedDate.replace(), timezone: new Date().getTimezoneOffset() }
        const formData = new FormData();
        for (let i in MOT) {
            formData.append(i, MOT[i])
        }
        try {
            const { data } = await Schedule(
                {
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    }
                }, formData,
                MOTID.id
            )
            props.changestheRaw(props.RawdataforMOT?.Holder?.map(templateItem => {
                return {
                    ...templateItem
                };
            }), 'scheduled')
            dispatch(SetMOT(false));
            dispatch(SetScheduleMOT(false));
            dispatch(StoreLanguage.setProps([RawData.Step2Success]))
            localStorage.removeItem('MOTID')
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    };
    return (
        <>
            <div className='px-[5%] bg-[#3b3f49] h-full'>
                <div className='h-1/2 min-h-[300px]'>
                    {
                        RawData.Holder.map(a => (
                            <Rinput data={{
                                Holder: a.title, Icon: a.Icon, Type: a.typeof, tags: a.tags, radius: a.range, from: 'MOT'
                            }}
                                Rinputdata={Rinputdata} />
                        ))
                    }
                </div>
                {MOTData.length === 3 && <div className='h-[40%] flex items-end Def-font'>
                    <div className="w-full mt-[3%] md:my-[5%] lg:mt-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer" onClick={() => SendMOTFun()}>
                        <p className="text-xl font-bold text-center uppercase text-[#333]">
                            {RawData.Button}
                        </p>
                    </div>
                </div>}
                {MOTData.length !== 3 && <div className='h-[40%] flex items-end Def-font'>
                    <div className="w-full mt-[3%] md:my-[5%] lg:mt-[6%] h-[58px] rounded-[10px] bg-gray-600 cursor-pointer flex justify-center">
                        <p className="text-xl font-bold text-center uppercase text-[#e0dada]  flex justify-center items-center">
                            {RawData.Button}
                        </p>
                    </div>
                </div>}
            </div>
        </>
    );
};

export default Profile;
