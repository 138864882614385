import { createSlice, configureStore } from '@reduxjs/toolkit';

const storeContentSlice = createSlice({
  name: 'storeContent',
  initialState: {
    language: 'eng',
    data: null,
    popup: [],
    ListofCars: [],
    AddNewPost: false,
    MOTServices: false,
    MapData: false,
    ScheduleMOT: false,
    profileStatus: false,
    UserData: {},
    BookMrkData: [],
    ApiUrlStructure: [],
    UserLoginData: {},
    PatchData: {},
    Tabdata: '',
    Ratting: {
      ID: 0,
      Status: false
    },
    search:'',
    DataStatus:''
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setProps: (state, action) => {
      state.popup = action.payload;
    },
    setsearch: (state, action) => {
      state.search = action.payload;
    },
    SetListofCars: (state, action) => {
      state.ListofCars = action.payload;
    },
    SetAddNewPost: (state, action) => {
      state.AddNewPost = action.payload;
    },
    SetMOT: (state, action) => {
      state.MOTServices = action.payload;
    },
    SetMap: (state, action) => {
      state.MapData = action.payload;
    },
    SetScheduleMOT: (state, action) => {
      state.ScheduleMOT = action.payload;
    },
    SetProfileStatus: (state) => {
      state.profileStatus = !state.profileStatus;
    },
    SetUserData: (state, action) => {
      state.UserData = action.payload;
    },
    SetBookMark: (state, action) => {
      state.BookMrkData = action.payload;
    },
    SetRatting: (state, action) => {
      state.Ratting = action.payload;
    },
    SetApiUrlStructure: (state, action) => {
      state.ApiUrlStructure = action.payload;
    },
    setUserLoginData: (state, action) => {
      state.UserLoginData = action.payload;
    },
    PatchPostedcar: (state, action) => {
      state.PatchData = action.payload;
    },
    SetTabData: (state, action) => {
      state.Tabdata = action.payload;
    },
    Status: (state, action) => {
      state.DataStatus = action.payload;
    },
  },
});

export const StoreLanguage = storeContentSlice.actions;
export const {
  setLanguage,
  setProps,
  SetListofCars,
  SetAddNewPost,
  SetMOT,
  SetMap,
  SetScheduleMOT,
  SetProfileStatus,
  SetUserData,
  SetBookMark,
  SetRatting,
  SetApiUrlStructure,
  setUserLoginData,
  PatchPostedcar,
  SetTabData,
  setsearch,
  Status
} = storeContentSlice.actions;
export const selectDataFromStore = (state) => state.storeContent.data;

const store = configureStore({
  reducer: {
    storeContent: storeContentSlice.reducer,
  },
});

export default store;
