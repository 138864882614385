import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreLanguage, SetProfileStatus, SetUserData } from './Store/index';
import HeaderNavBar from './Header/HeaderNavBar';
import MobileHeader from './Header/MobileHeader';
import language from '../views/Configurations/language';
import FooterNavBar from './Footer/FooterNavBar';
import Login from './RightBar/Login/Login';
import PopUp from './PopUp/PopUp';
import { Outlet, useParams, NavLink } from 'react-router-dom';
import Profile from './RightBar/Profile/Profile';
import ReviewPosts from './RightBar/ReviewPosts';
import Map from './Map/index';
import FooterMobile from '../assets/MobileFotter.svg';
import logo from '../assets/logo.svg';
import { SetLogOut, GetUserprofile, SetGLogin } from '../server/index';
import HomePageBanner from '../components/Banner/HomePageBanner';

function RouteLayout(options) {
    const dispatch = useDispatch();
    const [Welcome, SetWelcome] = useState(false);
    const [Signin, SetSignin] = useState(false);
    const [address, setAddress] = useState('');
    const [country, Setcountry] = useState('');
    const UnableProfile = useSelector(state => state.storeContent.profileStatus);
    const profiles = useSelector(state => state.storeContent.UserData);
    const MapData = useSelector(state => state.storeContent.MapData);
    let UserLanguage = useSelector(state => state.storeContent.StoreLanguage);
    if (!UserLanguage) {
        UserLanguage = localStorage.getItem(`Language`) ? localStorage.getItem(`Language`) : 'eng';
    }
    let params = useParams();
    const PopUpData = useSelector(state => state.storeContent.popup);
    const [compleatGeoLocation, setcompleatGeoLocation] = useState([]);
    const fetchData = async (Authorization) => {
        try {
            if (Authorization) {
                let { data } = await GetUserprofile({
                    headers: {
                        Authorization: `Token ${Authorization}`,
                    }
                })
                let tempdata = data
                dispatch(SetUserData({ Authorization, ...profiles, ...tempdata }))
            }
        } catch (error) {
        }
    };
    const checkfunction = async (Authorization) => {
        try {
            let { data } = await GetUserprofile({
                headers: {
                    Authorization: `Token ${Authorization}`,
                }
            })
            localStorage.setItem(`UserData`, JSON.stringify({ ...data, Authorization }));
            dispatch(SetUserData({ ...data, Authorization, phone_number_verified: true, user_password_updated: true }))
        } catch (error) {
        }
    }
    const loginThroughGmail = async (params) => {
        const { data } = await SetGLogin(params)
        fetchData(data?.auth_token);
        checkfunction(data?.auth_token);
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const params = {};
        queryParams.forEach((value, key) => {
            params[key] = value;
        });
        if (params?.code && params?.uuid) {
            loginThroughGmail(params)
            const newUrl = `${window.location.origin}${window.location.pathname}`;
            window.history.replaceState({}, document.title, newUrl);
        }
        // Scroll to top when component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            if (PopUpData?.Title || PopUpData?.length) {
                dispatch(StoreLanguage.setProps([]));
            }
        }, PopUpData?.length ? PopUpData?.length * 6000 : 3600);
    }, [PopUpData]);

    const UnableSignin = async (prop) => {
        if (prop) {
            try {
                let response = await SetLogOut({
                    headers: {
                        Authorization: `Token ${prop}`,
                    }
                });
                let data = response.data;
            } catch (error) {
                // Handle error
            }
        }
        SetSignin(!Signin);
    };

    useEffect(() => {
        // Scroll to top when the component mounts
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            SetWelcome(true);
        }, 1600);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

                    try {
                        const response = await fetch(apiUrl);
                        const data = await response.json();
                        if (data.address) {
                            const { city, postcode, suburb, county, country } = data.address;
                            setAddress(`${suburb}, ${county}, ${city}, ${postcode}`);
                            Setcountry(country);
                        }
                        setcompleatGeoLocation([position.coords, data]);
                    } catch (error) {
                        console.error('Error retrieving address:', error);
                    }
                },
                (error) => {
                    console.error('Error getting user location:', error.message);
                    if (error.message === 'Network error. Check DevTools console for more information.') {
                        dispatch(StoreLanguage.setProps(
                            [language[UserLanguage].PopUpData.ErrorMessage['InterNetError']]
                        ));
                    } else {
                        dispatch(StoreLanguage.setProps(
                            [language[UserLanguage].PopUpData.ErrorMessage['LocationError']]
                        ));
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const TriggerProfile = () => {
        dispatch(SetProfileStatus());
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {Welcome ? (
                <>
                    <div className='hidden md:block lg:block'>
                        <HeaderNavBar data={{ address, country }} language={language[UserLanguage]} UnableSignin={UnableSignin} />
                    </div>
                    {!params.Productid && <div className='block md:hidden lg:hidden pb-[58px]'>
                        <MobileHeader data={{ address, country }} language={language[UserLanguage]} UnableSignin={UnableSignin} />
                    </div>}
                    <div className='min-h-[100vh] overflow-auto m-0 pt-[0px] md:pt-[80px] lg:pt-[90px]'>
                        {PopUpData?.length > 0 && <PopUp data={PopUpData} />}
                        <Outlet data={UserLanguage} />
                        {Signin && <Login data={language[UserLanguage]} UnableSignin={UnableSignin} />}
                        {UnableProfile && <Profile data={language[UserLanguage].ProfileSettings} UnableSignin={UnableSignin} />}
                        {<ReviewPosts data={language[UserLanguage].DataALL} />}
                        {MapData && <Map data={compleatGeoLocation} lan={language[UserLanguage].Map} />}
                    </div>
                    <div className=''>
                        <HomePageBanner data={language[UserLanguage].lastBaner} />
                        <div className="pb-[20%] md:pb-0 lg:pb-0">
                            <FooterNavBar data={language[UserLanguage]} />
                        </div>
                    </div>
                    <div className='w-full fixed bottom-0 block lg:hidden md:hidden'>
                        <NavLink to={'/'}>
                            <img src={FooterMobile} className="w-full" alt="Footer Mobile" />
                        </NavLink>
                        <div className='w-full h-full absolute z-20 bottom-0 flex justify-between'>
                            {language[UserLanguage].Mobilefooter.map((a, i) => (
                                <div key={i} className={i === 1 ? 'w-[20%] h-full flex justify-between items-center' : 'w-[39.5%] h-full flex justify-between items-center'}>
                                    {a.map((b, ind) => (
                                        b.RedirectUrl ? (
                                            <NavLink key={ind} to={b.RedirectUrl} className={b.icon ? 'w-full' : 'w-full h-full flex justify-center'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                                <div className={b.icon ? 'w-full flex flex-col items-center justify-center py-3 pl-1' : 'w-[60px] flex flex-col items-center justify-center'}>
                                                    {b.icon && <img src={b.icon} className="w-[25px] h-[25px] mb-2" alt="Icon" />}
                                                    {!b.icon && <div className='w-full'></div>}
                                                    <p className="text-xs Def-font font-medium text-center text-white">{b.title}</p>
                                                </div>
                                            </NavLink>
                                        ) : (
                                            <div className={b.icon ? 'w-full flex flex-col items-center justify-center py-3 pl-1 cursor-pointer' : 'w-[60px] flex flex-col items-center justify-center cursor-pointer'} onClick={() => TriggerProfile()}>
                                                {b.icon && <img src={b.icon} className="w-[25px] h-[25px] mb-2" alt="Icon" />}
                                                {!b.icon && <div className='w-full'></div>}
                                                <p className="text-xs Def-font font-medium text-center text-white">{b.title}</p>
                                            </div>
                                        )
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className='shade-color pt-5'>{language[UserLanguage]?.welcomeMsg}</div>
                    <div className='loading-container pt-5'>
                        <div className='loading-spinner'>
                            <div className='spinner-circle'></div>
                        </div>
                        <div className='loading-text'>{language[UserLanguage].Loading}</div>
                    </div>
                </header>
            )}
        </>
    );
}

export default RouteLayout;
