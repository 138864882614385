import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { get_in_touch } from '../../server/index';
import { StoreLanguage } from '../Store/index';

function FooterNavBar(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const profiles = useSelector(state => state.storeContent.UserData);

    const [popUpData, setpopUpData] = useState(false);
    const [formData, setFormData] = useState({ email: '', description: '' });

    const sendRequest = async () => {
        try {
            await get_in_touch(
                profiles?.Authorization && {
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    },
                },
                { ...formData }
            );
            setpopUpData(false)
            setFormData({ email: '', description: '' })
            dispatch(StoreLanguage.setProps([{
                Title: 'Request Submitted Successfully',
                SubTitle: 'Thank you! Our team will contact you shortly.',
                Styling: {
                    Icon: 'Success',
                }
            }]));
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(
                    StoreLanguage.setProps([
                        {
                            Title: '500 server error',
                            SubTitle: '500 server error',
                            Styling: {
                                Icon: 'Error',
                            },
                        },
                    ])
                );
            } else {
                let temp = [];
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er])
                            ? error?.response?.data[er][0]
                            : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        },
                    });
                }
                dispatch(StoreLanguage.setProps(temp));
            }
        }
    };


    const close = () => {
        setpopUpData(false)
        setFormData({ email: '', description: '' })
    }
    const send = () => {
        sendRequest()
    }

    const handleChange = (event) => {
        let { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            {popUpData && (
                <div className='w-full min-h-full h-auto z-[12] bg_blur fixed top-0'>
                    <div className='w-full h-[100vh] flex justify-center items-center'>
                        <div className=' bg-[#3B3F49] w-full md:w-[50%] lg:w-[25%] rounded-lg'>
                            <div className='p-4'>
                                <div className='w-full flex justify-end'>
                                    <div
                                        className="w-[30px] h-[30px] rounded-full text-xl cursor-pointer text-white bg-[#62656D]"
                                        onClick={() => close()}
                                    >
                                        <div className="flex items-center justify-center">x</div>
                                    </div>
                                </div>
                                <div className='px-4 pb-[20px]'>
                                    <div>
                                        <div className='flex items-start flex-col text-white py-5'>
                                            <div className='text-lg pb-1 flex flex-col gap-3 w-full'>
                                                <div className='flex items-start flex-col'>
                                                    <div>Email</div>
                                                    <input
                                                        type="email"
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[50px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Email"
                                                        value={formData.email}
                                                        name='email'
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='flex items-start flex-col'>
                                                    <div>Description</div>
                                                    <textarea
                                                        className="outline-none w-full bg-white/[0.15] border border-[#bbb] pl-[20px] pr-[20px] h-[150px] text-white Def-font text-lg rounded-[10px]"
                                                        placeholder="Description"
                                                        rows="4"
                                                        value={formData.description}
                                                        name='description'
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-full flex items-center pb-2 justify-around`}>
                                    <div
                                        className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center"
                                        onClick={() => close()}
                                        style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}
                                    >
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Close
                                        </p>
                                    </div>
                                    <div
                                        className="w-[90px] h-8 rounded-[5px] cursor-pointer flex justify-center items-center"
                                        onClick={() => send()}
                                        style={{ background: "linear-gradient(220.6deg, #ffbe41 60.41%, #ff9334 121.42%)" }}
                                    >
                                        <p className="text-md font-bold text-center uppercase text-[rgb(51,51,51)] center_aline">
                                            Send
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FooterNavBar;
