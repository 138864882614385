import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
// import { get_in_touch } from '../../server/index';
// import { StoreLanguage } from '../Store/index';

function FooterNavBar(props) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const profiles = useSelector(state => state.storeContent.UserData);
    const [popUpData, setpopUpData] = useState(false);
    return (
        <>
            <div className='px-[20px] py-[20px] flex flex-col gap-[20px]'>
                <NavLink to={'/history'}>
                    <div className='flex justify-between border-b-[2px] pb-[20px] border-[#4D5763]'>
                        <div className='text-[#AAAAAA] text-[16px]'>
                            Sell / Buy
                        </div>
                        <div>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="#FFBA40" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </NavLink>
                <NavLink to={'/mot_history'}>
                    <div className='flex justify-between'>
                        <div className='text-[#AAAAAA] text-[16px]'>
                            MOT
                        </div>
                        <div>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="#FFBA40" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </NavLink>
            </div>
        </>
    );
}

export default FooterNavBar;