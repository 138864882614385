import React, { useState, useEffect, useRef } from "react";
import Rinput from "../../Helper/RInput";
import upload from "../../../assets/UploadLogo.svg";
import DownAerrow from "../../../assets/Down_Aerrow.svg";
import DefaultImage from "../../../assets/DefaultImage.svg";
import "./RateReviewStyle.css";
import {
    UploadCarData,
    SideFilter,
    usersList,
    UpdateCarData,
} from "../../../server/index";
import {
    StoreLanguage,
    PatchPostedcar,
    SetAddNewPost,
    Status,
} from "../../Store/index";
import { useDispatch, useSelector } from "react-redux";
import TopAerrow from "../../../assets/TopAerrow.svg";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
const Profile = (props) => {
    const dispatch = useDispatch();
    const [dataneed, setdataneed] = useState([]);
    const [sidefilterdata, setsidefilterdata] = useState({});
    const [UserLIst, setUserLIst] = useState([]);
    const [RawData, SetRawData] = useState(props.data);
    const [isDragging, setIsDragging] = useState(false);
    const profiles = useSelector((state) => state.storeContent.UserData);
    const PatchData = useSelector((state) => state.storeContent.PatchData);
    const [RawImages, setRawImages] = useState([]);
    const [FinalImages, setFinalImages] = useState([]);
    const [NewFinalImages, setNewFinalImages] = useState([]);
    const [hideCam, setHideCam] = useState(false);
    const [changeFormatted, setChangeFormatted] = useState([]);
    const [deleatedList, setDeleatedList] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
    const splitVehicleInfo = (info) => {
        const brands = Object.keys(info);
        const models = [];
        const modelVariations = [];
        for (const brand in info) {
            const brandModels = Object.keys(info[brand]);
            for (const model of brandModels) {
                const variations = info[brand][model];
                models.push(model);
                modelVariations.push(...variations);
            }
        }
        return [brands, models, modelVariations];
    };
    const mapingByTags = (APIData, Localdata) => {
        return APIData.map((a) => {
            const matchingData = Localdata.find((b) => b.tags === a.tags);
            if (matchingData) {
                return {
                    title: a.title,
                    important: a.important ? true : false,
                    ...matchingData,
                    ...a,
                };
            } else {
                return null;
            }
        }).filter((item) => item !== null);
    };
    const sidefilter = async () => {
        if (Object.keys(sidefilterdata).length) {
            return sidefilterdata;
        }
        try {
            const data = await SideFilter();
            setsidefilterdata(data);
            return data;
        } catch (error) {
            // Handle error
        }
    };
    const userData = async () => {
        try {
            const { data } = await usersList({
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                },
            });
            setUserLIst(data);
            return data;
        } catch (error) {
            return []
        }
    };
    const filterdata = async (PatchData, testData) => {
        try {
            // const { data } = await SideFilter();
            const { data } = await sidefilter();
            const foundBrands = {};
            if (testData) {
                const testDataLower = testData.toLowerCase();

                for (const brand in data.vehicle_car_info) {
                    if (brand.toLowerCase().includes(testDataLower)) {
                        foundBrands[brand] = data.vehicle_car_info[brand];
                    }
                }
            }
            const [brands, models, modelVariations] = splitVehicleInfo(
                testData ? foundBrands : data.vehicle_car_info
            );
            setChangeFormatted(PatchData.image_objs);
            const imageUrls =
                PatchData?.image_objs?.length &&
                PatchData.image_objs.map((a) => a.image_url);
            const formattedFeatures = Object.keys(PatchData.features || {})
                .map((feature) =>
                    PatchData.features[feature]
                        ? feature
                            .split("_")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")
                        : null
                )
                .filter((b) => b !== null);
            let listUserData = await userData();
            const ownerId = PatchData?.owner?.id;
            const defaultValue = ownerId ? listUserData.find((a) => a.id === ownerId)?.value : "";
            let formattedData = [
                {
                    dropDown: listUserData.map((a) => a.value),
                    tags: "owner",
                    defaultValue,
                    mustSlected: true,
                },
                {
                    dropDown: [...data.vehicle_car_types],
                    tags: "type",
                    defaultValue: PatchData?.type,
                    // mustSlected: true,
                },
                {
                    dropDown: brands,
                    tags: "brand",
                    defaultValue: PatchData?.brand,
                    // mustSlected: true,
                },
                {
                    dropDown: models,
                    tags: "model",
                    defaultValue: PatchData?.model,
                    // mustSlected: true,
                },
                {
                    dropDown: modelVariations,
                    tags: "varient",
                    defaultValue: PatchData?.varient,
                },
                {
                    dropDown: [],
                    tags: "year",
                    defaultValue: PatchData?.overview?.year,
                },
                {
                    dropDown: [...data.engine_sizes],
                    tags: "engine",
                    defaultValue: PatchData?.overview?.engine_displacement,
                },
                {
                    dropDown: [...data.seats],
                    tags: "seat_count",
                    holder: "Select Seat",
                    defaultValue: PatchData?.overview?.seating_capactity,
                    mustSlected: true,
                    Icon: DownAerrow,
                },
                {
                    tags: "price",
                    defaultValue: PatchData?.price,
                },
                {
                    tags: "description",
                    defaultValue: PatchData?.description,
                },
                {
                    tags: "km_driven",
                    defaultValue: PatchData?.overview?.km_driven,
                },
                {
                    dropDown: [],
                    tags: "accidental_history",
                    defaultValue: PatchData?.overview?.history,
                },
                {
                    tags: "last_service_on",
                    defaultValue: PatchData?.overview?.last_service_on,
                },
                {
                    tags: "last_service_km",
                    defaultValue: PatchData?.overview?.last_service_km,
                },
                {
                    tags: "registration_number",
                    defaultValue: PatchData?.overview?.registration_number,
                },
                {
                    tags: "registration_number_series",
                    defaultValue: PatchData?.registration_number_series,
                },
                {
                    tags: "registered_in",
                    defaultValue: PatchData?.registered_in,
                },
                {
                    tags: "array",
                    Icon: DownAerrow,
                    defaultValue: formattedFeatures?.filter((a) => a),
                },
                {
                    tags: "max_power",
                    defaultValue: PatchData?.specification?.max_power,
                },
                {
                    dropDown: data.transmission_type,
                    tags: "transmission_type",
                    holder: "Select Transmission Type",
                    Icon: DownAerrow,
                    defaultValue: PatchData?.specification?.transmission_type,
                    mustSlected: true,
                },
                {
                    tags: "boot_space_in_ltr",
                    defaultValue: PatchData?.specification?.boot_space_in_ltr,
                },
                {
                    tags: "drive_type",
                    defaultValue: PatchData?.specification?.drive_type,
                },
                {
                    tags: "front_suspension",
                    defaultValue: PatchData?.specification?.front_suspension,
                },
                {
                    tags: "rear_suspension",
                    defaultValue: PatchData?.specification?.rear_suspension,
                },
                {
                    tags: "kerb_weight",
                    defaultValue: PatchData?.specification?.kerb_weight,
                },
                {
                    tags: "gross_weight",
                    defaultValue: PatchData?.specification?.gross_weight,
                },
                {
                    dropDown: data.fuel_type,
                    Icon: DownAerrow,
                    tags: "fuel",
                    holder: "Select Fuel Type",
                    mustSlected: true,
                    defaultValue: PatchData?.overview?.fuel,
                },
                {
                    tags: "mileage",
                    defaultValue: parseFloat(PatchData?.overview?.mileage),
                },
                {
                    tags: "engine_displacement",
                    defaultValue: parseFloat(PatchData?.overview?.engine_displacement),
                },
            ];
            formattedData = !profiles?.is_staff
                ? formattedData.filter((a) => a.tags != "owner")
                : formattedData;
            if (imageUrls) {
                setFinalImages(imageUrls);
            }
            setdataneed(mapingByTags(props.data.Holder, formattedData));
        } catch (error) { }
    };
    useEffect(() => {
        if (PatchData) {
            filterdata(PatchData);
        }
    }, [PatchData]);

    // useEffect(() => {
    //     filterdata()
    // }, []);

    const [postData, setpostData] = useState([]);
    const handleFileInputChange = (event) => {
        const files = event?.target?.files ? event?.target?.files : event;
        fileHandel(files);
    };
    const fileHandel = async (files) => {
        const imageFiles = [];
        const videoFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith("image/")) {
                imageFiles.push(file);
            } else if (file.type.startsWith("video/")) {
                videoFiles.push(file);
            }
        }
        setRawImages([...RawImages, ...imageFiles, ...videoFiles]);
        const urls = await Promise.all(
            [...RawImages, ...imageFiles, ...videoFiles].map(File)
        );
        setNewFinalImages([...new Set([...urls])]);
    };
    const Rinputdata = (prop) => {
        if (prop.Name === "brand") {
            filterdata("", prop.UserEnterData);
        }
        const uniqueEntries = new Map();
        [...postData, { ...prop }].forEach((entry) => {
            const { Name, UserEnterData, showPopup } = entry;
            const ownerData = UserLIst.filter((a) => a.value == UserEnterData)[0];
            let dataToSet = ownerData?.id
                ? { UserEnterData: ownerData.id }
                : showPopup
                    ? { UserEnterData, showPopup }
                    : { UserEnterData };
            uniqueEntries.set(Name, dataToSet);
        });

        const result = Array.from(uniqueEntries, ([Name, data]) => ({
            Name,
            ...data,
        }));
        const updatedDataNeed = dataneed.map((item) => {
            const matchingResult = result.find(
                (resultItem) => resultItem.Name === item.tags
            );
            if (matchingResult) {
                return { ...item, defaultValue: matchingResult.UserEnterData };
            }
            return item;
        });
        setdataneed(updatedDataNeed);
        setpostData(result);
    };
    const Uploaddata = async () => {
        let checkdata = postData.filter((res) => res.showPopup === "NeedToSlected");
        setUploadButtonDisabled(true);
        if (checkdata.length) {
            let temp = [];
            for (let er in checkdata) {
                temp.push({
                    Title: "Select From Dropdown",
                    SubTitle: `${checkdata[er].Name.charAt(0).toUpperCase() +
                        checkdata[er].Name.slice(1).replace(/_/g, " ")
                        } is not matching please select from dropdown.`,
                    Styling: {
                        Icon: "Error",
                    },
                });
            }
            dispatch(StoreLanguage.setProps(temp));
            return;
        }
        let converted = Reduce(
            postData.map((a) => ({ [a.Name]: a.UserEnterData }))
        );
        let FormatImage = Reduce(RawImages.map((a, i) => ({ [`image_${i}`]: a })));
        let finalData = { ...FormatImage, ...converted };
        const formData = new FormData();
        for (let i in finalData) {
            formData.append(i, finalData[i]);
        }
        try {
            if (Object.keys(PatchData).length) {
                formData.append("delete_images", deleatedList);
                let { data } = await UpdateCarData(
                    {
                        headers: {
                            Authorization: `Token ${profiles?.Authorization}`,
                        },
                    },
                    formData,
                    PatchData.id
                );
                dispatch(SetAddNewPost(false));
                dispatch(PatchPostedcar({}));
                dispatch(Status(data));
            } else {
                let { data } = await UploadCarData(
                    {
                        headers: {
                            Authorization: `Token ${profiles?.Authorization}`,
                        },
                    },
                    formData
                );
                dispatch(SetAddNewPost(false));
                dispatch(PatchPostedcar({}));
                dispatch(Status(data));
            }
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(
                    StoreLanguage.setProps([
                        {
                            Title: "500 server error",
                            SubTitle: "500 server error",
                            Styling: {
                                Icon: "Error",
                            },
                        },
                    ])
                );
            } else {
                let temp = [];
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er])
                            ? error?.response?.data[er][0]
                            : error?.response?.data[er],
                        Styling: {
                            Icon: "Error",
                        },
                    });
                }
                dispatch(StoreLanguage.setProps(temp));
            }
        } finally {
            setUploadButtonDisabled(false);
        }
    };
    const File = (files) => {
        return new Promise((resolve) => {
            const file = files;
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                resolve(null);
            }
        });
    };
    const Reduce = (converted) => {
        return converted.reduce((acc, obj) => {
            Object.keys(obj).forEach((key) => {
                acc[key] = obj[key];
            });
            return acc;
        }, {});
    };
    const CloseImg = (props, url, check) => {
        if (!Object.keys(PatchData).length) {
            let Alldata = [...RawImages];
            let urls = [...NewFinalImages];
            Alldata.splice(props, 1);
            urls.splice(props, 1);
            setRawImages(Alldata);
            setNewFinalImages(urls);
        } else {
            let urls = [...FinalImages];
            let newurls = [...NewFinalImages];
            let Alldata = [...RawImages];
            if (check) {
                newurls.splice(props, 1);
            } else {
                urls.splice(props, 1);
            }
            Alldata.splice(props, 1);
            if (changeFormatted.length) {
                setDeleatedList([
                    ...deleatedList,
                    changeFormatted?.find((a) => a.image_url === url)?.id,
                ]);
            }
            setRawImages(Alldata);
            if (check) {
                setNewFinalImages(newurls);
            } else {
                setFinalImages(urls);
            }
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        fileHandel(files);
    };
    const showCamara = () => {
        setHideCam(!hideCam);
    };
    const handleTakePhoto = (dataUri) => {
        const blob = dataUriToBlob(dataUri);
        const fileName = "captured_media";
        const mimeType = getMimeType(dataUri);
        const fileExtension = getFileExtension(mimeType);
        const customFile = new CustomFile([blob], `${fileName}.${fileExtension}`, {
            type: mimeType,
        });
        fileHandel([customFile]);
        setHideCam(!hideCam);
    };

    class CustomFile extends Blob {
        constructor(chunks, fileName, options) {
            super(chunks, options);
            this.name = fileName;
        }
    }

    const dataUriToBlob = (dataUri) => {
        const byteString = atob(dataUri.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: getMimeType(dataUri) });
    };

    const getMimeType = (dataUri) => {
        const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
        return mimeString;
    };

    const getFileExtension = (mimeType) => {
        if (mimeType.startsWith("image/")) {
            return "jpg";
        } else if (mimeType.startsWith("video/")) {
            return "mp4";
        } else {
            return "unknown";
        }
    };

    return (
        <>
            <div className="px-[5%] bg-[#3b3f49] Def-font pb-[30%] md:pb-5 lg:pb-5">
                {hideCam && (
                    <div className="pt-[5%]">
                        <Camera
                            onTakePhoto={(dataUri, mimeType) =>
                                handleTakePhoto(dataUri, mimeType)
                            }
                            idealFacingMode="environment"
                        />
                        <button
                            className="mt-3 p-2 bg-red-400 rounded-md"
                            onClick={() => showCamara()}
                        >
                            Cancel
                        </button>
                    </div>
                )}
                {!hideCam &&
                    dataneed.map((a, index) => (
                        <Rinput
                            key={index}
                            data={{
                                Holder: a.title,
                                Icon: a.Icon,
                                Clear: TopAerrow,
                                important: a.important,
                                Type: a.typeof,
                                tags: a.tags,
                                defaultValue: a.defaultValue,
                                features: a.features,
                                mustSlected: a.mustSlected,
                                placeHolder: a.holder,
                            }}
                            dropDown={a.Icon && a.dropDown}
                            Rinputdata={Rinputdata}
                        />
                    ))}
                <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-8">
                    {FinalImages.length > 0 &&
                        FinalImages?.map((dataURL, index) => (
                            <div
                                className="w-[95px] md:w-[105px] lg:w-[90%] h-[135px] m-3 relative"
                                key={index}
                            >
                                <div
                                    className="w-[25px] h-[25px] absolute top-[-10px] right-[-10px] bg-[#4B4E58] text-emerald-50 text-md rounded-full cursor-pointer z-10"
                                    onClick={() => CloseImg(index, dataURL)}
                                >
                                    x
                                </div>
                                {dataURL.startsWith("data:video/mp4;base64,") ||
                                    dataURL.includes(".mp4") ? (
                                    <video
                                        className="w-[100%] h-[135px] object-contain"
                                        key={index}
                                        controls
                                    >
                                        <source src={dataURL} type="video/mp4" />
                                        Your browser does not support the video
                                    </video>
                                ) : (
                                    <img
                                        className="w-[100%] h-[135px] object-contain"
                                        src={dataURL}
                                        alt={`Image ${index}`}
                                    />
                                )}
                            </div>
                        ))}
                    {NewFinalImages.length > 0 &&
                        NewFinalImages?.map((dataURL, index) => (
                            <div
                                className="w-[95px] md:w-[105px] lg:w-[90%] h-[135px] m-3 relative"
                                key={index}
                            >
                                <div
                                    className="w-[25px] h-[25px] absolute top-[-10px] right-[-10px] bg-[#4B4E58] text-emerald-50 text-md rounded-full cursor-pointer z-10"
                                    onClick={() => CloseImg(index, dataURL, "new")}
                                >
                                    x
                                </div>
                                {dataURL.startsWith("data:video/") ||
                                    dataURL.includes(".mp4") ? (
                                    <video
                                        className="w-[100%] h-[135px] object-contain"
                                        key={index}
                                        controls
                                    >
                                        <source src={dataURL} type="video/mp4" />
                                        Your browser does not support the video
                                    </video>
                                ) : (
                                    <img
                                        className="w-[100%] h-[135px] object-contain"
                                        src={dataURL}
                                        alt={`Image ${index}`}
                                    />
                                )}
                            </div>
                        ))}
                    {!(FinalImages.length > 4) && !(NewFinalImages?.length > 4) && (
                        <>
                            <div
                                className={
                                    FinalImages?.length > 0 || NewFinalImages?.length > 0
                                        ? "p-[14px]"
                                        : ""
                                }
                            >
                                <div
                                    className={`w-[135px] h-[135px] flex flex-col items-center justify-center rounded-[10px] bg-white/[0.08] border border-[#666] ${isDragging ? "border-blue-500" : ""
                                        }`}
                                    style={{
                                        boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.15)",
                                    }}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <label htmlFor="file-upload" className="cursor-pointer">
                                        <img src={upload} alt="" />
                                    </label>
                                    <p>
                                        <span className="text-lg text-center text-[#aaa]">
                                            {RawData.PhotoTitle}
                                        </span>
                                    </p>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*, video/*"
                                        className="hidden"
                                        multiple
                                        onChange={handleFileInputChange}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    FinalImages?.length > 0 || NewFinalImages?.length > 0
                                        ? "py-[14px]"
                                        : ""
                                }
                            >
                                <div
                                    className={`ml-5 cursor-pointer w-[135px] h-[135px] flex flex-col items-center justify-center rounded-[10px] bg-white/[0.08] border border-[#666] ${isDragging ? "border-blue-500" : ""
                                        }`}
                                    onClick={() => showCamara()}
                                    style={{
                                        boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.15)",
                                    }}
                                >
                                    <label className="">
                                        <img src={upload} alt="" />
                                    </label>
                                    <p>
                                        <span className="text-lg text-center text-[#aaa]">
                                            Take Photo
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {/* <div className='py-2 w-full text-white cursor-pointer' onClick={() => showCamara()}>Take Pick</div> */}
                <div
                    className={`w-full md:my-[3%] lg:mt-[6%] h-[58px] rounded-[10px] Login_Best cursor-pointer ${uploadButtonDisabled ? "opacity-50 pointer-events-none" : ""
                        }`}
                    onClick={() => Uploaddata()}
                >
                    <p className="text-xl font-bold text-center uppercase text-[#333]">
                        {dataneed.Button ? dataneed.Button : "Post"}
                    </p>
                </div>
            </div>
        </>
    );
};

export default Profile;