const EmptyScreen = () => {
    return (
        <>
            <div className="w-[325px] md:w-[725px] lg:w-[925px] h-[139px] md:h-[270px] lg:h-[409px]">
                <div
                    className="w-[325px] md:w-[725px] lg:w-[925px] h-[139px] md:h-[270px] lg:h-[409px] opacity-60 rounded-[30px] bg-[#ffffff26] border border-white"
                    style={{ boxShadow: " 0px 2px 34px 0 rgba(0,0,0,0.18)" }}
                >
                    <div className='w-full h-full flex justify-center items-center'>
                        <div className="w-[458px] h-[121px] flex flex-col justify-center">
                            <p className="text-[22px] md:text-[27px] lg:text-[34px] font-medium text-center text-white">
                                No result found
                            </p>
                            <div className='w-full flex justify-center'>
                                <p className="w-[258px] md:w-[358px] lg:w-[458px] text-sm md:text-md lg:text-xl text-center text-white">
                                    Sorry we couldn’t find any matches based on your search, please try again.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default EmptyScreen;
