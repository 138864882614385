import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import warning from '../../assets/Dander.svg';
import Rinput from '../Helper/RInput';
import Wicon from '../../assets/Gmap/LocationWarning.svg';
import Sicon from '../../assets/Gmap/LocalSucess.svg';
import Location from '../../assets/Gmap/location.svg';
// import { ErrorMessage } from '../../views/Configurations/language';
import { useDispatch, useSelector } from 'react-redux';
import 'leaflet-geosearch/dist/geosearch.css';
import { StoreLanguage, SetMap } from '../Store/index'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { createroadassistrequest } from '../../server/index'
import './indexstyle.css';

function Gmap(props) {
  const dispatch = useDispatch()
  const [language, setlanguage] = useState(props.lan)
  const profiles = useSelector(state => state.storeContent.UserData);
  const currency_obj = {
    "USD": { "name": "United States Dollar", "symbol": "$", "format": "en-US", "key": "USD", "timezone": "GMT-5:00" },
    "EUR": { "name": "Euro", "symbol": "€", "format": "en-US", "key": "EUR", "timezone": "GMT+1:00" },
    "JPY": { "name": "Japanese Yen", "symbol": "¥", "format": "ja-JP", "key": "JPY", "timezone": "GMT+9:00" },
    "GBP": { "name": "British Pound Sterling", "symbol": "£", "format": "en-GB", "key": "GBP", "timezone": "GMT+0:00" },
    "CAD": { "name": "Canadian Dollar", "symbol": "C$", "format": "en-CA", "key": "CAD", "timezone": "GMT-5:00" },
    "AUD": { "name": "Australian Dollar", "symbol": "A$", "format": "en-AU", "key": "AUD", "timezone": "GMT+10:00" },
    "CHF": { "name": "Swiss Franc", "symbol": "CHF", "format": "de-CH", "key": "CHF", "timezone": "GMT+1:00" },
    "CNY": { "name": "Chinese Yuan", "symbol": "¥", "format": "zh-CN", "key": "CNY", "timezone": "GMT+8:00" },
    "INR": { "name": "Indian Rupee", "symbol": "₹", "format": "en-IN", "key": "INR", "timezone": "GMT+5:30" },
    "SGD": { "name": "Singapore Dollar", "symbol": "S$", "format": "en-SG", "key": "SGD", "timezone": "GMT+8:00" },
    "NZD": { "name": "New Zealand Dollar", "symbol": "NZ$", "format": "en-NZ", "key": "NZD", "timezone": "GMT+12:00" },
    "BRL": { "name": "Brazilian Real", "symbol": "R$", "format": "pt-BR", "key": "BRL", "timezone": "GMT-3:00" },
    "RUB": { "name": "Russian Ruble", "symbol": "₽", "format": "ru-RU", "key": "RUB", "timezone": "GMT+3:00" },
    "ZAR": { "name": "South African Rand", "symbol": "R", "format": "en-ZA", "key": "ZAR", "timezone": "GMT+2:00" },
    "AED": { "name": "United Arab Emirates Dirham", "symbol": "د.إ", "format": "ar-AE", "key": "AED", "timezone": "GMT+4:00" },
    "MXN": { "name": "Mexican Peso", "symbol": "Mex$", "format": "es-MX", "key": "MXN", "timezone": "GMT-6:00" },
    "HKD": { "name": "Hong Kong Dollar", "symbol": "HK$", "format": "en-HK", "key": "HKD", "timezone": "GMT+8:00" },
    "SEK": { "name": "Swedish Krona", "symbol": "kr", "format": "sv-SE", "key": "SEK", "timezone": "GMT+1:00" },
    "NOK": { "name": "Norwegian Krone", "symbol": "kr", "format": "nb-NO", "key": "NOK", "timezone": "GMT+1:00" },
    "DKK": { "name": "Danish Krone", "symbol": "kr", "format": "da-DK", "key": "DKK", "timezone": "GMT+1:00" },
    "TRY": { "name": "Turkish Lira", "symbol": "₺", "format": "tr-TR", "key": "TRY", "timezone": "GMT+3:00" },
    "PLN": { "name": "Polish Złoty", "symbol": "zł", "format": "pl-PL", "key": "PLN", "timezone": "GMT+1:00" },
    "THB": { "name": "Thai Baht", "symbol": "฿", "format": "th-TH", "key": "THB", "timezone": "GMT+7:00" },
    "IDR": { "name": "Indonesian Rupiah", "symbol": "Rp", "format": "id-ID", "key": "IDR", "timezone": "GMT+7:00" },
    "MYR": { "name": "Malaysian Ringgit", "symbol": "RM", "format": "ms-MY", "key": "MYR", "timezone": "GMT+8:00" },
    "PHP": { "name": "Philippine Peso", "symbol": "₱", "format": "en-PH", "key": "PHP", "timezone": "GMT+8:00" },
    "COP": { "name": "Colombian Peso", "symbol": "Col$", "format": "es-CO", "key": "COP", "timezone": "GMT-5:00" },
    "ARS": { "name": "Argentine Peso", "symbol": "ARS$", "format": "es-AR", "key": "ARS", "timezone": "GMT-3:00" },
    "EGP": { "name": "Egyptian Pound", "symbol": "EGP", "format": "ar-EG", "key": "EGP", "timezone": "GMT+2:00" },
    "ILS": { "name": "Israeli New Shekel", "symbol": "₪", "format": "he-IL", "key": "ILS", "timezone": "GMT+2:00" },
    "KRW": { "name": "South Korean Won", "symbol": "₩", "format": "ko-KR", "key": "KRW", "timezone": "GMT+9:00" },
    "TWD": { "name": "New Taiwan Dollar", "symbol": "NT$", "format": "zh-TW", "key": "TWD", "timezone": "GMT+8:00" },
    "VND": { "name": "Vietnamese Dong", "symbol": "₫", "format": "vi-VN", "key": "VND", "timezone": "GMT+7:00" },
    "NGN": { "name": "Nigerian Naira", "symbol": "₦", "format": "en-NG", "key": "NGN", "timezone": "GMT+1:00" },
    "CLP": { "name": "Chilean Peso", "symbol": "CLP$", "format": "es-CL", "key": "CLP", "timezone": "GMT-3:00" },
    "CZK": { "name": "Czech Koruna", "symbol": "Kč", "format": "cs-CZ", "key": "CZK", "timezone": "GMT+1:00" },
    "PKR": { "name": "Pakistani Rupee", "symbol": "₨", "format": "ur-PK", "key": "PKR", "timezone": "GMT+5:00" },
    "BGN": { "name": "Bulgarian Lev", "symbol": "лв", "format": "bg-BG", "key": "BGN", "timezone": "GMT+2:00" },
    "HRK": { "name": "Croatian Kuna", "symbol": "kn", "format": "hr-HR", "key": "HRK", "timezone": "GMT+1:00" },
    "RON": { "name": "Romanian Leu", "symbol": "lei", "format": "ro-RO", "key": "RON", "timezone": "GMT+2:00" },
    "HUF": { "name": "Hungarian Forint", "symbol": "Ft", "format": "hu-HU", "key": "HUF", "timezone": "GMT+1:00" },
    "ISK": { "name": "Icelandic Króna", "symbol": "kr", "format": "is-IS", "key": "ISK", "timezone": "GMT+0:00" },
    "NPR": { "name": "Nepalese Rupee", "symbol": "₨", "format": "ne-NP", "key": "NPR", "timezone": "GMT+5:45" },
    "LKR": { "name": "Sri Lankan Rupee", "symbol": "₨", "format": "si-LK", "key": "LKR", "timezone": "GMT+5:30" },
    "DZD": { "name": "Algerian Dinar", "symbol": "دج", "format": "ar-DZ", "key": "DZD", "timezone": "GMT+1:00" },
    "IRR": { "name": "Iranian Rial", "symbol": "﷼", "format": "fa-IR", "key": "IRR", "timezone": "GMT+3:30" },
    "GHS": { "name": "Ghanaian Cedi", "symbol": "GH₵", "format": "en-GH", "key": "GHS", "timezone": "GMT+0:00" },
    "HNL": { "name": "Honduran Lempira", "symbol": "L", "format": "es-HN", "key": "HNL", "timezone": "GMT-6:00" },
    "CDF": { "name": "Congolese Franc", "symbol": "FC", "format": "fr-CD", "key": "CDF", "timezone": "GMT+1:00" },
    "UGX": { "name": "Ugandan Shilling", "symbol": "USh", "format": "en-UG", "key": "UGX", "timezone": "GMT+3:00" },
    "BMD": { "name": "Bermudian Dollar", "symbol": "BD$", "format": "en-BM", "key": "BMD", "timezone": "GMT-4:00" },
    "AMD": { "name": "Armenian Dram", "symbol": "֏", "format": "hy-AM", "key": "AMD", "timezone": "GMT+4:00" },
    "NAD": { "name": "Namibian Dollar", "symbol": "N$", "format": "en-NA", "key": "NAD", "timezone": "GMT+2:00" },
    "AON": { "name": "Angolan New Kwanza", "symbol": "AON", "format": "pt-AO", "key": "AON", "timezone": "GMT+1:00" },
    "BAM": { "name": "Bosnia-Herzegovina Convertible Mark", "symbol": "KM", "format": "bs-Latn-BA", "key": "BAM", "timezone": "GMT+1:00" },
    "LSL": { "name": "Lesotho Loti", "symbol": "L", "format": "en-LS", "key": "LSL", "timezone": "GMT+2:00" },
    "MMK": { "name": "Burmese Kyat", "symbol": "K", "format": "my-MM", "key": "MMK", "timezone": "GMT+6:30" },
    "PGK": { "name": "Papua New Guinean Kina", "symbol": "K", "format": "en-PG", "key": "PGK", "timezone": "GMT+10:00" },
    "PYG": { "name": "Paraguayan Guarani", "symbol": "₲", "format": "es-PY", "key": "PYG", "timezone": "GMT-4:00" },
    "SLL": { "name": "Sierra Leonean Leone", "symbol": "Le", "format": "en-SL", "key": "SLL", "timezone": "GMT+0:00" },
    "TMT": { "name": "Turkmenistani Manat", "symbol": "T", "format": "tk-TM", "key": "TMT", "timezone": "GMT+5:00" },
    "UYU": { "name": "Uruguayan Peso", "symbol": "$U", "format": "es-UY", "key": "UYU", "timezone": "GMT-3:00" },
    "VES": { "name": "Venezuelan Bolívar Soberano", "symbol": "Bs.", "format": "es-VE", "key": "VES", "timezone": "GMT-4:00" },
    "YER": { "name": "Yemeni Rial", "symbol": "﷼", "format": "ar-YE", "key": "YER", "timezone": "GMT+5:30" },
  };
  const [propsData, setPropsData] = useState([]);
  const [position, setPosition] = useState([]);
  const [InputData, SetInputData] = useState({});
  const [isWithinBounds, setIsWithinBounds] = useState(true);
  const [countryCode, setCountryCode] = useState('IN')
  const [customIcon, setCustomIcon] = useState(
    L.icon({
      iconSize: [305, 305],
      popupAnchor: [1, -34],
    })
  );
  const [searchResults, setSearchResults] = useState(null); // Added state for search results
  const mapRef = useRef(null);
  // const searchInputRef = useRef(null);
  useEffect(() => {
    if (props.data.length) {
      setPropsData(props.data);
    } else {
      dispatch(StoreLanguage.setProps([props.lan.ErrorMessageMap]));
      dispatch(SetMap(false));
    }
  }, [props.data]);

  useEffect(() => {
    if (profiles.country) {
      for (let i in currency_obj) {
        if (currency_obj[i].name === profiles.country)
          setCountryCode(currency_obj[i].countryCode)
      }
    }
  }, [profiles.country]);

  useEffect(() => {
    if (props.data.length) {
      setPropsData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (propsData.length) {
      const { latitude, longitude } = propsData[0];
      setPosition([latitude, longitude]);
      const Bounds = L.latLngBounds([6.753515, 68.162386], [35.674545, 97.395555]);
      // const Bounds = L.latLngBounds([24.7549, 66.7849], [25.1865, 67.2715])
      const isWithinBounds = Bounds.contains(L.latLng(latitude, longitude));
      setCustomIcon(
        L.icon({
          iconUrl: isWithinBounds ? Sicon : Wicon,
          iconSize: [45, 45],
          popupAnchor: [1, -34],
        })
      );
      setIsWithinBounds(isWithinBounds);
    }
  }, [propsData]);

  const handleMapClick = (event) => {
    // if (event && event.latlng) {
    //   const { lat, lng } = event.latlng;
    //   setPosition([lat, lng]);
    //   mapRef.current.eachLayer((layer) => {
    //     if (layer instanceof L.Marker) {
    //       layer.remove();
    //     }
    //   });
    // }

    if (!searchResults) {
      const { lat, lng } = event.latlng;
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.remove();
        }
      });
      const newMarker = L.marker([lat, lng], { icon: customIcon }).addTo(mapRef.current);
      setPosition([lat, lng]);
    }
  };

  // const handleConfirm = () => {
  //   if (mapRef.current) {
  //     mapRef.current.setView(position);
  //   }
  // };

  // const handleSearch = () => {
  //   const inputValue = searchInputRef.current.value;
  //   const provider = new OpenStreetMapProvider({
  //     params: {
  //       countrycodes: 'IN', // Restrict search to India
  //     },
  //   });

  //   provider.search({ query: inputValue })
  //     .then((results) => {
  //       if (results.length > 0) {
  //         const { y: latitude, x: longitude } = results[0].bounds;
  //         setPosition([latitude, longitude]);

  //         const Bounds = L.latLngBounds([24.7549, 66.7849], [25.1865, 67.2715])
  //         const isWithinBounds = Bounds.contains(L.latLng(latitude, longitude));
  //         setCustomIcon(
  //           L.icon({
  //             iconUrl: isWithinBounds ? Sicon : Wicon,
  //             iconSize: [305, 305],
  //             popupAnchor: [1, -34],
  //           })
  //         );
  //         setIsWithinBounds(isWithinBounds);
  //         setSearchError(!isWithinBounds);
  //       }
  //     })
  //     .catch((error) => {
  //       setSearchError(true);
  //     });
  // };

  const Close = () => {
    dispatch(SetMap(false));
  }

  const SearchControl = () => {
    const map = useMap();

    const handleSearchResult = (event) => {
      // if (!searchResults) {
      const { x: longitude, y: latitude } = event.location;
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.remove();
        }
      });
      const newMarker = L.marker([latitude, longitude], { icon: customIcon }).addTo(mapRef.current);
      setPosition([latitude, longitude]);
      // }
    };

    // const handleSearchResult = (event) => {
    //   const { x: longitude, y: latitude } = event.location
    //   setPosition([latitude, longitude])
    // };

    useMapEvents({
      click: handleMapClick,
    });

    useEffect(() => {
      const provider = new OpenStreetMapProvider({
        params: {
          countrycodes: countryCode, // Restrict search to India
        },
      });
      const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        autoComplete: true,
        autoClose: true,
        searchLabel: language.searchLabel,
        keepResult: true,
        marker: {
          icon: customIcon,
        },
      });
      map.addControl(searchControl);

      map.on('geosearch/showlocation', handleSearchResult);
      return () => {
        map.removeControl(searchControl);
        map.off('geosearch/showlocation', handleSearchResult);
      };
    }, [map, countryCode, customIcon]);

    return null;
  };

  function LocationResetButton({ resetLocation }) {
    const map = useMap();

    const handleResetClick = () => {
      const { latitude, longitude } = propsData[0];
      map.flyTo([latitude, longitude], 10);
      resetLocation();
    };

    useEffect(() => {
      const resetButtonControl = L.Control.extend({
        options: {
          position: 'topright',
        },

        onAdd: () => {
          const container = L.DomUtil.create(
            'div',
            'leaflet-bar leaflet-control leaflet-control-custom'
          );
          container.innerHTML = `<button className="" title="Reset Location"><img src=${Location} className="App-logo" alt="logo" /></button>`;

          L.DomEvent.disableClickPropagation(container);
          L.DomEvent.on(container, 'click', handleResetClick);
          return container;
        },
      });

      const resetButton = new resetButtonControl();
      resetButton.addTo(map);

      return () => {
        resetButton.remove();
      };
    }, []);

    return null;
  }
  const ConformDeatil = async () => {
    try {
      const { data } = await createroadassistrequest(
        {
          headers: {
            Authorization: `Token ${profiles?.Authorization}`,
          }
        },
        {
          [InputData.Name]: InputData.UserEnterData,
          "location": `${position[0]},${position[1]}`
        });
      dispatch(StoreLanguage.setProps(
        [props.lan.SuccessMessageMap]
      ))
      dispatch(SetMap(false));
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(StoreLanguage.setProps([{
          Title: '500 server error',
          SubTitle: '500 server error',
          Styling: {
            Icon: 'Error',
          }
        }]));
      } else {
        let temp = []
        for (let er in error?.response?.data) {
          temp.push({
            Title: er,
            SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
            Styling: {
              Icon: 'Error',
            }
          })
        }
        dispatch(StoreLanguage.setProps(temp))
      }
    }
  }
  const Rinputdata = (props) => {
    SetInputData(props)
  }
  const handleResetLocation = () => {
    // Reset your location state or perform any other necessary actions
    // setLocation([51.505, -0.09]);
  };

  return (
    <>
      {position.length > 0 && (
        <div className="w-full h-full bg_blur fixed top-0 z-[12]  overflow-y-scroll Def-font">
          <div className="w-[30px] h-[30px] absolute right-[5%] z-[13] top-[3%] md:top-[4%] lg:top-[5%] z-8 rounded-full flex justify-center items-center text-xl cursor-pointer text-white bg-[#62656D]" onClick={() => Close()}>x</div>
          <div className="w-full md:w-[35%] lg:w-[45%] min-h-full h-auto absolute right-0 bg-[#3B3F49]">
            <div className="w-full">
              <div className="py-[50px]">
                <p className="text-2xl text-center text-white">Road Assist</p>
              </div>
              <hr className="h-px bg-[#72787C] border-0" />
              <div className="flex items-center justify-center pb-[20px]">
                <div className="w-[80%]">
                  <Rinput data={{ Holder: language.InputTitle, Type: 'text', tags: 'vehicle_license_plate' }} Rinputdata={Rinputdata} />
                </div>
              </div>
              <div className="flex justify-center">
                <MapContainer
                  center={position}
                  zoom={18}
                  style={{ height: '400px', width: '80%', borderRadius: '10px' }}
                  ref={mapRef}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker position={position} icon={customIcon} />
                  <SearchControl />
                  <LocationResetButton resetLocation={handleResetLocation} />
                </MapContainer>
              </div>
              {!isWithinBounds && (
                <div className="flex items-center justify-center w-full py-[35px]">
                  <div className="w-[80%] h-[58px]">
                    <div className="w-full h-auto py-[8px] px-[15px] rounded-md bg-[#ed1e43] flex items-center">
                      <img src={warning} className="h-[28px] " alt="logo" />
                      <p className="w-[80%] text-[16px] text-left text-white pl-[15px]">
                        {language.subText}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-center items-center cursor-pointer" onClick={() => ConformDeatil()}>
                <div className="w-[80%] mt-[3%] md:my-[5%] lg:mt-[6%] h-[58px] rounded-[10px] Login_Best">
                  <p className="text-xl font-bold text-center uppercase text-[#333]">{language.button}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Gmap;
