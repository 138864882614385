import React, { useState, useEffect } from 'react';
import Card from '../Helper/cards'
import { useDispatch, useSelector } from 'react-redux';
// import { SetUserData, SetBookMark, StoreLanguage } from '../Store/index'
// import { ErrorMessage } from '../../views/Configurations/language'
import { StoreLanguage } from '../Store/index'
import { SetBookmarks, CreateBookmarks } from '../../server/index'
const BookMark = (prop) => {
    const dispatch = useDispatch()
    const [propData, SetpropData] = useState(prop.data)
    useEffect(() => {
        SetpropData(prop.data)
    }, [prop]);
    // let BookMrkData = useSelector(state => state.storeContent.BookMrkData);
    const [GetBookMrkData, SetBookMrkData] = useState([])
    const profiles = useSelector(state => state.storeContent.UserData);
    useEffect(() => {
        if (profiles && profiles?.Authorization) bookmarkdata(profiles)
        // const userdata = JSON.parse(localStorage.getItem(`UserData`))
        // dispatch(SetUserData(userdata))
        // if (BookMrkData && BookMrkData.length) {

        // } else {
        //     let Rawdata = JSON.parse(localStorage.getItem(`${profiles?.name && userdata?.name}BookMrkData`));
        //     if (Rawdata && Rawdata.length) {
        //         dispatch(SetBookMark(Rawdata))
        //     }
        // }
    }, [profiles]);
    const AddToBookMark = async (profiles, props) => {
        try {
            await CreateBookmarks(profiles?.Authorization && {
                headers: {
                    Authorization: `Token ${profiles?.Authorization}`,
                }
            }, {
                "car_vehicle": props.car_vehicle.id
            })
            bookmarkdata(profiles)
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    }
    const bookmarkdata = async (profiles, props) => {
        try {
            if (props?.car_vehicle.id) {
                const { data } = await SetBookmarks({
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    }
                }, {
                    "car_vehicle": props.car_vehicle.id
                }
                );
                SetBookMrkData(data)
            } else {
                const { data } = await SetBookmarks({
                    headers: {
                        Authorization: `Token ${profiles?.Authorization}`,
                    }
                });
                SetBookMrkData(data)
            }
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(StoreLanguage.setProps([{
                    Title: '500 server error',
                    SubTitle: '500 server error',
                    Styling: {
                        Icon: 'Error',
                    }
                }]));
            } else {
                let temp = []
                for (let er in error?.response?.data) {
                    temp.push({
                        Title: er,
                        SubTitle: Array.isArray(error?.response?.data[er]) ? error?.response?.data[er][0] : error?.response?.data[er],
                        Styling: {
                            Icon: 'Error',
                        }
                    })
                }
                dispatch(StoreLanguage.setProps(temp))
            }
        }
    };
    return (
        <>
            <div className='w-full px-[5%] py-[2%] Def-font'>
                <div className='h-[250px] md:h-[320px] lg:h-[360px] relative'>
                    <div style={{ backgroundImage: `url(${propData.BookmarkData.MainBanner})`, borderRadius: '24px' }} className="w-full h-full bg-center bg-no-repeat bg-cover"></div>
                    <div className='absolute top-0 left-[80px] right-0 bottom-0 flex items-center justify-start'>
                        <p className="text-left w-[300px] md:w-[270px] lg:w-[430px]">
                            <span className="text-[1rem] md:text-[1.5rem] lg:text-5xl text-left text-white">{propData.BookmarkData.subtitle}</span>
                        </p>
                    </div>
                </div>
                {GetBookMrkData && <Card data={GetBookMrkData} AllData={propData.BookmarkData.Cards} nodata={propData.BookmarkData.NoData} AddToBookMark={AddToBookMark} />}
            </div>
            {propData.subbanner}
        </>
    );
};

export default BookMark;
